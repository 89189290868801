import { Patient } from '../appointment-flow-screens/service/types';
import { v4 as uuidv4 } from 'uuid';

export const savePatient = (patient: Patient): void => {
  localStorage.setItem('patient', JSON.stringify(patient));
};

export const deletePatient = (): void => {
  localStorage.removeItem('patient');
};

export const getPatient = (): Patient | undefined =>
  localStorage.getItem('patient') ? (JSON.parse(localStorage.getItem('patient') as string) as Patient) : undefined;

export const getAppId = (): string => {
  const APP_ID = 'appId';
  let id = localStorage.getItem(APP_ID);
  if (!id) {
    id = uuidv4().substring(0, 8);
    localStorage.setItem(APP_ID, id);
  }
  return id;
};
