export default function TermsOfUsePage() {
  return (
    <div className="main-container wrap privacy">
      <h1>Conditions d'utilisation Agenda</h1>
      <h2 className="main-subtitle">Mise à jour le 10 Octobre 2023</h2>

      <ol className="ordered-list">
        <li className="list-item">
          <p>
            L’Agenda de rendez-vous médicaux développé par MEDISPRING (ci-après l’Agenda) est mis gratuitement à
            disposition des patients qui souhaitent prendre rendez-vous avec tout prestataire de soins qui a souscrit
            une licence d’utilisation du Logiciel MEDISPRING.
          </p>
          <p>
            L’accès à l’Agenda se fait exclusivement via l’interface web développée par MEDISPRING. Les rendez-vous avec
            les prestataires de soins sont sollicités via un formulaire on line dont tous les champs doivent être
            remplis par l’Utilisateur.
          </p>
          <p>
            Un rendez-vous n’est confirmé que lorsque l’Utilisateur a introduit dans le formulaire on line le code
            d’authentification qui lui a été adressé par MEDISPRING. Ce code lui est adressé à l’adresse mail qu’il a
            renseignée dans le cadre de sa réservation.
          </p>
        </li>

        <li className="list-item">
          <p>
            MEDISPRING se réserve le droit de procéder à la désactivation immédiate et sans préavis de l’accès de
            l’Utilisateur à l’Agenda et/ou de son compte utilisateur dans les hypothèses suivantes :
          </p>
          <ul>
            <li>
              L’Utilisateur active un rendez-vous auprès d’un prestataire de soins sous une fausse identité ou usurpe
              l’identité d’un tiers pour prendre un rendez-vous que celui-ci n’a jamais sollicité.
            </li>
            <li>
              L’Utilisateur tente de s’introduire de manière frauduleuse dans les systèmes de MEDSIRPING ou de
              compromettre la sécurité et l’intégrité de ceux-ci.
            </li>
            <li>
              L’Utilisateur ne se présente pas aux rendez-vous qu’il sollicite malgré les rappels qui lui sont adressés
              par mail.
            </li>
            <li>
              Le Prestataire de soins auprès duquel l’Utilisateur a pris rendez-vous se plaint de harcèlement ou de tout
              autre comportement inapproprié de celui-ci.
            </li>
          </ul>
        </li>

        <li className="list-item">
          Les données récoltées par MEDISRPING via le formulaire de prise de rendez-vous sont traitées pour les
          finalités et selon les modalités précisées dans la Politique de confidentialité de MEDISPRING, accessibles via
          le lien suivant :{' '}
          <a href="https://www.medispring.be/politique-de-vie-priv%C3%A9e.html" target="_blank">
            Politique de vie privée
          </a>
        </li>

        <li className="list-item">
          Les litiges relatifs à l’application des présentes Conditions d’utilisation sont de la compétence exclusive
          des tribunaux de l’arrondissement du Brabant wallon.
        </li>
      </ol>
    </div>
  );
}
