import {ShareMetadataBehaviour} from '@icure/api';

export interface TypeAndPropertyNames {
  type: string;
  propertyNames: string[];
}

export const ENCRYPTED_ENTITIES_TYPE_AND_PROPERTY_NAMES: TypeAndPropertyNames[] = [
  {
    type: 'AccessLog',
    propertyNames: ['accessType', 'objectId'],
  },
  {
    type: 'CalendarItem',
    propertyNames: [
      'agendaId',
      'allDay',
      'calendarItemTypeId',
      'cancellationTimestamp',
      'confirmationId',
      'confirmationTime',
      'homeVisit',
      'masterCalendarItemId',
      'meetingTags',
      'recurrenceId',
    ],
  },
  {
    type: 'Classification',
    propertyNames: ['templateId'],
  },
  {
    type: 'Contact',
    propertyNames: ['encounterType', 'services', 'subContacts'],
  },
  {
    type: 'Document',
    propertyNames: [
      'attachmentId',
      'decryptedAttachment',
      'deletedAttachments',
      'encryptedAttachment',
      'externalUri',
      'documentLocation',
      'documentStatus',
      'documentType',
      'mainUti',
      'objectStoreReference',
      'otherUtis',
      'secondaryAttachments',
      'storedICureDocumentId',
    ],
  },
  {
    type: 'Form',
    propertyNames: ['formTemplateId', 'planOfActionId'],
  },
  {
    type: 'HealthElement',
    propertyNames: [
      'careTeam',
      'episodes',
      'laterality',
      'idClosingContact',
      'idOpeningContact',
      'idService',
      'plansOfAction',
      'relevant',
    ],
  },
  {
    type: 'Invoice',
    propertyNames: [
      'correctedInvoiceId',
      'correctiveInvoiceId',
      'interventionType',
      'invoiceDate',
      'invoicePeriod',
      'invoiceReference',
      'invoiceType',
      'longDelayJustification',
      'paymentType',
      'printedDate',
      'recipientId',
      'recipientType',
      'receipts',
      'sentDate',
      'sentMediumType',
      'thirdPartyPaymentJustification',
      'thirdPartyPaymentReason',
      'thirdPartyReference',
    ],
  },
  {
    type: 'MaintenanceTask',
    propertyNames: ['taskType'],
  },
  {
    type: 'Message',
    propertyNames: [
      'assignedResults',
      'conversationGuid',
      'fromAddress',
      'fromHealthcarePartyId',
      'invoiceIds',
      'readStatus',
      'recipientsType',
      'senderReferences',
      'toAddresses',
      'transportGuid',
      'unassignedResults',
    ],
  },
  {
    type: 'Patient',
    propertyNames: [
      'firstName',
      'lastName',
      'gender',
      'mergeToPatientId',
      'mergedIds',
      'deactivationReason',
      'deactivationDate',
      'ssin',
      'dateOfBirth',
      'dateOfDeath',
      'timestampOfLatestEidReading',
      'administrativeNote',
      'picture',
      'insurabilities',
      'patientHealthCareParties',
      'financialInstitutionInformation',
      'medicalHouseContracts',
    ],
  },
  {
    type: 'Receipt',
    propertyNames: ['attachmentIds', 'category', 'subCategory'],
  },
  {
    type: 'Service',
    propertyNames: [
      'content',
      'dataClassName',
      'healthElementsIds',
      'qualifiedLinks',
      'plansOfActionIds',
      'subContactIds',
      'transactionId',
    ],
  },
];

export interface ShareMetadataBehaviours {
  shareEncryptionKey?: ShareMetadataBehaviour;
  sharePatientId?: ShareMetadataBehaviour;
  shareMessageId?: ShareMetadataBehaviour;
  shareSecretIds?: string[];
}
export interface ShareMetadataBehavioursByDelegateId {
  [delegateId: string]: ShareMetadataBehaviours;
}
