import { useTranslation } from 'react-i18next';
import { AppointmentTypeAndPlace } from '@icure/api';
import React from 'react';
import { API_TIME_FORMAT } from '../../../services/constants';
import dayjs from 'dayjs';
import { Patient, ScreenName } from '../service/types';
import { FlowState } from '../../AppointmentFlow';
import { selectAppointmentTypesByPlaceId } from '../../../services/utils';
import { APPOINTMENT_FLOW_SCREENS } from '../../appointmentScreenlist';

interface Selections {
  appointmentType?: AppointmentTypeAndPlace;
  timeslot?: number;
  patient?: Patient;
}

interface Props {
  untilScreen: ScreenName;
  // eslint-disable-next-line
  gotoScreen: (screenName: ScreenName, {}: FlowState) => void;
  selections: Selections;
  flowState: FlowState;
}

const isUntil = (screenName: ScreenName, untilScreen: ScreenName): boolean =>
  APPOINTMENT_FLOW_SCREENS.findIndex(({ name }) => name === screenName) <=
  APPOINTMENT_FLOW_SCREENS.findIndex(({ name }) => name === untilScreen);

export const HistorySteps = ({
  untilScreen,
  gotoScreen,
  selections: { appointmentType, timeslot, patient },
  flowState,
}: Props) => {
  const { t } = useTranslation();

  const getCalendarItemTypeChoiceTitle = () => (
    <li className="list-item">
      <h3 className="screen-title">{t('FLOW.CALENDAR_ITEM_TYPE_CHOICE.TITLE')} </h3>
      {appointmentType?.name ? (
        <button
          className="flow-screen-link"
          disabled={selectAppointmentTypesByPlaceId(flowState.appointmentTypes!, flowState.placeId!).length === 1}
          onClick={() => gotoScreen(ScreenName.CALENDAR_ITEM_TYPE_CHOICE, { appointmentType: {} })}
        >
          {appointmentType?.name}
        </button>
      ) : (
        ''
      )}
    </li>
  );
  const getTimeslotChoiceTitle = () => (
    <li className="list-item">
      <h3 className="screen-title">{t('FLOW.TIMESLOT_CHOICE.TITLE')} </h3>
      {timeslot ? (
        <button
          className="flow-screen-link"
          onClick={() => gotoScreen(ScreenName.TIMESLOT_CHOICE, { timeslot: undefined })}
        >
          {dayjs(String(timeslot), API_TIME_FORMAT).format('dddd D MMM HH:mm')}
        </button>
      ) : (
        ''
      )}
    </li>
  );
  const getIdentificationTitle = () => (
    <li className="list-item">
      <h3 className="screen-title">{t('FLOW.IDENTIFICATION.TITLE')} </h3>
      {timeslot ? (
        <a className="flow-screen-link" onClick={() => gotoScreen(ScreenName.IDENTIFICATION, {})}>
          {patient?.firstName} {patient?.lastName}
          <br />
          <span className="details">
            {patient?.email}
            <br />
            {patient?.phoneNumber}
            <br />
          </span>
        </a>
      ) : (
        ''
      )}
    </li>
  );
  return (
    <React.Fragment>
      {isUntil(ScreenName.CALENDAR_ITEM_TYPE_CHOICE, untilScreen) ? getCalendarItemTypeChoiceTitle() : ''}
      {isUntil(ScreenName.TIMESLOT_CHOICE, untilScreen) ? getTimeslotChoiceTitle() : ''}
      {isUntil(ScreenName.IDENTIFICATION, untilScreen) ? getIdentificationTitle() : ''}
    </React.Fragment>
  );
};
