import {IcureTokens} from '../model/credentials.model';

const ICURE_TOKEN_IDENTIFIER: string = 'icure-token';
const ICURE_REFRESH_TOKEN_IDENTIFIER: string = 'icure-refresh-token';
const ICURE_TOKEN_CLOUD_IDENTIFIER: string = 'icure-token-cloud';
const ICURE_REFRESH_TOKEN_CLOUD_IDENTIFIER: string = 'icure-refresh-token-cloud';
const ICURE_GROUPID_IDENTIFIER: string = 'icure-group-id';
const ICURE_USERNAME: string = 'icure-username';
const ICURE_PASSWORD: string = 'icure-password';

export class IcureTokensHelper {
  /**
   * **Gets Icure token** from session storage.
   * @returns The Icure token from session storage (or undefined).
   */
  public static getToken(): string | undefined {
    return sessionStorage.getItem(ICURE_TOKEN_IDENTIFIER) || undefined;
  }

  /**
   * **Gets Icure token cloud** from session storage.
   * @returns The Icure token cloud from session storage (or undefined).
   */
  public static getTokenCloud(): string | undefined {
    return sessionStorage.getItem(ICURE_TOKEN_CLOUD_IDENTIFIER) || undefined;
  }

  /**
   * **Gets Icure refresh token** from session storage.
   * @returns The Icure refresh token from session storage (or undefined).
   */
  public static getRefreshToken(): string | undefined {
    return sessionStorage.getItem(ICURE_REFRESH_TOKEN_IDENTIFIER) || undefined;
  }

  /**
   * **Gets Icure refresh token cloud** from session storage.
   * @returns The Icure refresh token cloud from session storage (or undefined).
   */
  public static getRefreshTokenCloud(): string | undefined {
    return sessionStorage.getItem(ICURE_REFRESH_TOKEN_CLOUD_IDENTIFIER) || undefined;
  }

  /**
   * **Gets Icure tokens** from session storage (providing both token && refreshToken are found).
   * @returns The Icure tokens from session storage (or undefined if either is missing).
   */
  public static getTokens(): IcureTokens | undefined {
    const iCureToken: string | undefined = IcureTokensHelper.getToken();
    const iCureRefreshToken: string | undefined = IcureTokensHelper.getRefreshToken();

    return iCureToken && iCureRefreshToken
      ? ({ token: iCureToken, refreshToken: iCureRefreshToken } as IcureTokens)
      : undefined;
  }

  /**
   * **Gets Icure tokens cloud** from session storage (providing both token && refreshToken are found).
   * @returns The Icure tokens cloud from session storage (or undefined if either is missing).
   */
  public static getTokensCloud(): IcureTokens | undefined {
    const iCureToken: string | undefined = IcureTokensHelper.getTokenCloud();
    const iCureRefreshToken: string | undefined = IcureTokensHelper.getRefreshTokenCloud();

    return iCureToken && iCureRefreshToken
      ? ({ token: iCureToken, refreshToken: iCureRefreshToken } as IcureTokens)
      : undefined;
  }

  /**
   * **Gets Icure groupId** from session storage.
   * @returns The Icure groupId from session storage (or undefined).
   */
  public static getGroupId(): string | undefined {
    return sessionStorage.getItem(ICURE_GROUPID_IDENTIFIER) || undefined;
  }

  /**
   * **Gets Icure username** from session storage.
   * @returns The Icure username from session storage (or undefined).
   */
  public static getUserName(): string | undefined {
    return sessionStorage.getItem(ICURE_USERNAME) || undefined;
  }

  /**
   * **Gets Icure password** from session storage.
   * @returns The Icure password from session storage (or undefined).
   */
  public static getPassword(): string | undefined {
    return sessionStorage.getItem(ICURE_PASSWORD) || undefined;
  }

  /**
   * **Saves Icure token** to session storage.
   * @param value The Icure token value to save.
   * @returns given Icure token value.
   */
  public static saveToken(value: string): string {
    sessionStorage.setItem(ICURE_TOKEN_IDENTIFIER, value);
    return value;
  }

  /**
   * **Saves Icure token cloud** to session storage.
   * @param value The Icure token cloud value to save.
   * @returns given Icure token cloud value.
   */
  public static saveTokenCloud(value: string): string {
    sessionStorage.setItem(ICURE_TOKEN_CLOUD_IDENTIFIER, value);
    return value;
  }

  /**
   * **Saves Icure refresh token** to session storage.
   * @param value The Icure refresh token value to save.
   * @returns given Icure refresh token value.
   */
  public static saveRefreshToken(value: string): string {
    sessionStorage.setItem(ICURE_REFRESH_TOKEN_IDENTIFIER, value);
    return value;
  }

  /**
   * **Saves Icure refresh token cloud** to session storage.
   * @param value The Icure refresh token cloud value to save.
   * @returns given Icure refresh token cloud value.
   */
  public static saveRefreshTokenCloud(value: string): string {
    sessionStorage.setItem(ICURE_REFRESH_TOKEN_CLOUD_IDENTIFIER, value);
    return value;
  }

  /**
   * **Saves Icure tokens** to session storage (only when both token && refreshToken are provided).
   * @param value The Icure tokens value to save.
   * @returns given Icure tokens value.
   */
  public static saveTokens(value: IcureTokens | undefined): IcureTokens | undefined {
    if (value?.token && value?.refreshToken) {
      IcureTokensHelper.saveToken(value.token);
      IcureTokensHelper.saveRefreshToken(value.refreshToken);
    }
    return value;
  }

  /**
   * **Saves Icure tokens cloud** to session storage (only when both token && refreshToken are provided).
   * @param value The Icure tokens cloud value to save.
   * @returns given Icure tokens cloud value.
   */
  public static saveTokensCloud(value: IcureTokens | undefined): IcureTokens | undefined {
    if (value?.token && value?.refreshToken) {
      IcureTokensHelper.saveTokenCloud(value.token);
      IcureTokensHelper.saveRefreshTokenCloud(value.refreshToken);
    }
    return value;
  }

  /**
   * **Saves Icure groupId** to session storage.
   * @param value The Icure groupId value to save.
   * @returns given Icure groupId value.
   */
  public static saveGroupId(value: string): string {
    sessionStorage.setItem(ICURE_GROUPID_IDENTIFIER, value);
    return value;
  }

  /**
   * **Saves Icure username** to session storage.
   * @param value The Icure username value to save.
   * @returns given Icure username value.
   */
  public static saveUsername(value: string): string {
    sessionStorage.setItem(ICURE_USERNAME, value);
    return value;
  }

  /**
   * **Saves Icure password** to session storage.
   * @param value The Icure password value to save.
   * @returns given Icure password value.
   */
  public static savePassword(value: string): string {
    sessionStorage.setItem(ICURE_PASSWORD, value);
    return value;
  }

  /**
   * **Deletes Icure tokens** from session storage.
   * @returns void
   */
  public static deleteTokens(): void {
    sessionStorage.removeItem(ICURE_TOKEN_IDENTIFIER);
    sessionStorage.removeItem(ICURE_REFRESH_TOKEN_IDENTIFIER);
  }

  /**
   * **Deletes Icure tokens cloud** from session storage.
   * @returns void
   */
  public static deleteTokensCloud(): void {
    sessionStorage.removeItem(ICURE_TOKEN_CLOUD_IDENTIFIER);
    sessionStorage.removeItem(ICURE_REFRESH_TOKEN_CLOUD_IDENTIFIER);
  }

  /**
   * **Deletes Icure groupId** from session storage.
   * @returns void
   */
  public static deleteGroupId(): void {
    sessionStorage.removeItem(ICURE_GROUPID_IDENTIFIER);
  }

  /**
   * **Deletes Icure username** from session storage.
   * @returns void
   */
  public static deleteUsername(): void {
    sessionStorage.removeItem(ICURE_USERNAME);
  }

  /**
   * **Deletes Icure password** from session storage.
   * @returns void
   */
  public static deletePassword(): void {
    sessionStorage.removeItem(ICURE_PASSWORD);
  }

  /**
   * **Deletes Icure username, groupId, tokens, tokens cloud** from session storage.
   *
   * Method is just an alias to invalidate everything upon logout / initialization failure.
   *
   * @returns void
   */
  public static deleteAll(): void {
    IcureTokensHelper.deleteUsername();
    IcureTokensHelper.deletePassword();
    IcureTokensHelper.deleteGroupId();
    IcureTokensHelper.deleteTokens();
    IcureTokensHelper.deleteTokensCloud();
  }
}
