import {FlowState} from '../AppointmentFlow';
import {configureNewPatient, sendValidationEmail} from '../../services/apiService';
import {ClassNames, ERROR, Patient, ScreenName} from './service/types';
import {BaseSyntheticEvent, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isValidCode, updatePatient} from './service/ValidationService';
import {v4 as uuidv4} from 'uuid';
import ReCAPTCHA from 'react-google-recaptcha';
import {trackGoal} from 'fathom-client';
import {FathomError} from '../../constants/fathom.constants';
import * as Sentry from '@sentry/browser';
import {getErrorMessage} from '../../services/utils';

interface Props {
  flowState: FlowState;
  gotoScreen: (screenName: ScreenName, {}: FlowState) => void;
  onSubmit: ({}: FlowState) => void;
}

export const Validation = ({
  flowState,
  flowState: { patient, groupId, rememberMe, healthcareParty },
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [requestId] = useState<string>(uuidv4());
  const [code, setCode] = useState<string>('');
  const [errorCode, setErrorCode] = useState<ERROR | undefined>(ERROR.ID_CODE_INVALID);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const handleChange = ({ target: { value } }: BaseSyntheticEvent) => {
    setErrorCode(isValidCode(code) ? undefined : ERROR.ID_CODE_INVALID);
    setCode(value);
  };

  useEffect(() => {
    (async () => {
      const recaptchaToken = await recaptchaRef?.current?.executeAsync();
      if (recaptchaToken) {
        sendValidationEmail(recaptchaToken, flowState, requestId);
      } else {
        Sentry.captureException(new Error('Recaptcha Error'));
        throw new Error(ERROR.RECAPTCHA);
      }
    })();
  }, [patient]);

  const submit = async () => {
    setLoading(true);
    setSubmitted(true);
    setErrorCode(undefined);
    try {
      const updatedPatient: Patient = await updatePatient(
        requestId,
        healthcareParty!.id!,
        patient!,
        code,
        !!rememberMe,
      );
      try {
        await configureNewPatient(updatedPatient!, code, healthcareParty!, groupId!);
        setLoading(false);
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        const errorName: string = (e as Error).message;
        if (Object.values(ERROR).includes(errorName as ERROR)) {
          throw new Error(ERROR[errorName as ERROR]);
        }
        throw new Error(ERROR.PATIENT_CONFIGURATION);
      }
      onSubmit({ patient: updatedPatient, patientToken: code });
    } catch (e) {
      setLoading(false);
      setErrorCode(((e as Error).message ?? e) as ERROR);
      trackGoal(FathomError[e as ERROR]!, 1);
      throw e;
    }
  };

  return (
    <div className="VerificationCode">
      <h3
        className="screen-title review"
        dangerouslySetInnerHTML={{ __html: t('FLOW.VERIFICATION_CODE.CALL_TO_ACTION', { email: patient?.email }) }}
      />
      <input
        className={`form-input textfield code-input ${errorCode ? '' : ClassNames.validated} ${submitted ? ClassNames.submitted : ''}`}
        id="validation-code"
        onInput={handleChange}
        maxLength={6}
        autoComplete="off"
      />
      <p className="small-text review" dangerouslySetInnerHTML={{ __html: t('FLOW.IDENTIFICATION.REVIEW') }} />

      <button id="confirmation-button" disabled={loading} className="button primary" onClick={() => submit()}>
        {t('BUTTONS.NEXT')}
      </button>
      {errorCode && submitted ? <div className="error-message"><strong>{getErrorMessage(errorCode)}</strong></div> : ''}

      <ReCAPTCHA sitekey="6Lccx2QfAAAAAP4NHvOD1hxT91fs5zieSFP-PYXo" ref={recaptchaRef} size="invisible" />
    </div>
  );
};
export default Validation;
