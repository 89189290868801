import React from 'react';

export default function PrivacyPage() {
  return (
    <div className="main-container wrap privacy">
      <h1>Politique de vie privée</h1>
      <h2 className="main-subtitle">Mise à jour le XX/XX/XXXX</h2>

      <ol className="ordered-list">
        <li className="list-item">
          <span className="flow-title">Qui sommes-nous ?</span>
          <br />
          - Nom : MediSPRING SCRL (la « Société », « nous », « nos/notre »)
          <br />
          - Siège : 41 Rue de Stierlinsart, 5070 Fosses-la-Ville (Belgique)
          <br />
          - Numéro d’entreprise : 0697.560.553
          <br />
          - Logiciel : Medispring (le « Logiciel »)
          <br />
          - Service : la mise à disposition du Logiciel (le « Service »)
          <br />
          - Site web : www.medispring.be (le « Site web »)
          <br />- Coordonnées de notre Délégué à la Protection des Données : RGPD@medispring.be
        </li>

        <li className="list-item">
          <span className="flow-title">
            Qui sont les personnes concernées ?<br />
          </span>
          2.1 - Nous traitons des données à caractère personnel relatives :<br />
          - aux utilisateurs qui utilisent le Logiciel mis à disposition par nos clients dans le cadre du Service ;
          <br />
          - aux patients de nos clients ;<br />
          - aux représentants de nos fournisseurs ;<br />
          - aux candidats à un travail chez nous ;<br />
          - aux visiteurs de notre Site web et de nos lieux de travail ; et
          <br />
          - à d'autres personnes concernées, (les « personnes concernées », « vous », « votre/vos »).
          <br />
          <br />
          2.2 - Cette politique de vie privée (la « Politique ») s’applique à tout traitement par la Société de vos
          données à caractère personnel.
        </li>
        <li className="list-item">
          <span className="flow-title">Quel est notre engagement en matière de protection des données ?</span>
          <br />
          3.1 - Nous nous engageons à déployer nos meilleurs efforts pour rendre nos activités de traitement de données
          à caractère personnel conformes à la législation sur la protection des données applicable, en ce compris le
          Règlement (UE) 2016/679 relatif à la protection des personnes physiques à l'égard du traitement des données à
          caractère personnel et à la libre circulation de ces données, et abrogeant la Directive 95/46/CE (Règlement
          Général sur la Protection des Données) (le « RGPD ») et la Loi du 30 juillet 2018 relative à la protection des
          personnes physiques à l'égard des traitements de données à caractère personnel, telle que modifiée, supplée ou
          remplacée de temps en temps (la « Législation sur la Protection des Données Applicable »).
        </li>

        <li className="list-item">
          <span className="flow-title">
            <b>
              Pour quelles finalités traitons-nous vos données à caractère personnel ?<br />
            </b>
          </span>
          4.1 - Si vous êtes un membre du personnel qui utilise le Logiciel, nous traitons :<br />
          - vos données d'identification personnelle, vos données de contact, vos données d'identification
          professionnelle et votre numéro INAMI (le cas échéant) pour créer votre profil utilisateur sur le Logiciel ;
          <br />
          - les dates des rendez-vous que vous organisez ;<br />
          - vos données de contact pour permettre à notre équipe de support de vous contacter dans le cadre d’activités
          de support ;<br />
          - vos données d'identification électronique pour vous authentifier sur le Logiciel, pour l’activation, la
          suspension et le retrait de comptes utilisateurs et pour la détection et la prévention des fraudes et des
          failles de sécurité informatique.
          <br />
          <br />
          4.2 - Si vous êtes un patient, nous traitons :<br />
          - vos données d'identification personnelle, vos données de contact, votre date de naissance et votre numéro de
          registre national pour permettre aux membres du personnel de nos clients de vous identifier sur le Logiciel ;
          <br />
          - les dates et lieux de rendez-vous chez les clients pour permettre aux membres du personnel de nos clients
          d'organiser les rendez-vous médicaux avec vous ;<br />
          - votre dossier patient (contenant des données concernant votre santé) pour faciliter le diagnostic et le
          suivi de votre traitement par les membres du personnel de nos clients.
          <br />
          <br />
          4.3 - Si vous êtes le représentant d’un de nos clients, nous traitons :<br />
          - vos données d’identification personnelle, vos données d’identification professionnelle et vos données de
          contact pour l'activation, la gestion et le maintien de notre relation commerciale avec nos clients ;<br />
          - vos données d'identification personnelle et vos données d'identification professionnelle pour nous permettre
          d’organiser la facturation de nos clients ;<br />
          - le cas échéant, vos coordonnées bancaires pour vérifier le paiement des factures liées à votre utilisation
          du Logiciel ;<br />
          - le cas échéant, vos données d'identification personnelle, vos données d'identification professionnelle et
          vos données de contact pour l'envoi de communications à des fins marketing ;<br />
          - vos données d’identification personnelle, vos données d’identification professionnelle et vos données de
          contact pour nous permettre d'organiser les formations sur le Logiciel ;<br />
          - vos données d’identification personnelle, vos données d’identification professionnelle et vos données de
          contact pour nous permettre d'organiser la procédure d’installation du Logiciel et de migration de vos données
          vers le Logiciel.
          <br />
          <br />
          4.4 - Si vous êtes le représentant d’un de nos fournisseurs, nous traitons vos données d’identification
          personnelle, vos données d’identification professionnelle et vos données de contact pour la gestion de notre
          relation commerciale avec nos fournisseurs.
          <br />
          <br />
          4.5 - Si vous êtes un candidat à un travail chez nous, nous traitons vos données d’identification personnelle,
          vos données d’identification professionnelle, vos données de contact, les données relatives à votre vie
          professionnelle (compétences, qualifications, expériences, etc.) et les données à caractère personnel
          contenues dans votre curriculum vitae pour évaluer votre profil par rapport à nos besoins de recrutement.
          <br />
          <br />
          4.6 - Si vous visitez notre Site web, nous sommes susceptibles de traiter des données d'identification
          électronique vous concernant de manière agrégée pour mesurer la fréquence sur notre Site web, améliorer
          l'expérience de navigation et pour la détection et la prévention des fraudes et des failles de sécurité
          informatique.
          <br />
          <br />
          4.7 - Si nos lieux de travail sont équipés par des caméras de surveillance, nous sommes en mesure de demander
          un accès à des images vous concernant, uniquement lorsque cet accès est nécessaire à la poursuite de notre
          intérêt légitime de détection d’infractions ou d’incivilités et dans la mesure autorisée par la loi
          applicable.
          <br />
          <br />
          4.8 - Nous sommes également susceptibles de traiter certaines de vos données à caractère personnel pour les
          finalités suivantes :<br />
          - conduire des opérations de restructuration de nos activités;
          <br />
          - réaliser des audits internes et externes ;<br />
          - gérer des litiges avec les clients, les fournisseurs et d’autres personnes concernées et lorsque le
          traitement est nécessaire à la constatation, l’exercice ou la défense d’un droit en justice.
          <br />
          <br />
          4.9 - Nous ne soumettons pas les personnes concernées à des décisions fondées exclusivement sur un traitement
          automatisé produisant des effets juridiques les concernant ou les affectant de manière similairement
          significative.
        </li>
        <li className="list-item">
          <span className="flow-title">
            En quelle capacité traitons-nous vos données à caractère personnel ?<br />
          </span>
          5.1 - Nous traitons vos données à caractère personnel en capacité de responsable du traitement, à l’exception
          des données à caractère personnel des membres du personnel qui utilisent le Logiciel et celles des patients
          que nous traitons en capacité de sous-traitant pour le compte des clients.
          <br />
          <br />
          5.2 - Toutefois, nous traitons en capacité de responsable du traitement les données d'identification
          électronique des utilisateurs concernés aux fins de les authentifier sur le Logiciel, pour l’activation, la
          suspension et le retrait de leurs comptes et pour la détection et la prévention des fraudes et des failles de
          sécurité informatique.
        </li>
        <li className="list-item">
          <span className="flow-title">
            Sur quelles bases traitons-nous vos données à caractère personnel ?<br />
          </span>
          6.1 - La fourniture de vos données à caractère personnel peut être nécessaire :<br />-{' '}
          <span>
            à l’exécution d’un contrat auquel la personne concernée est partie (par exemple, le cas échéant, le contrat
            de fourniture du Service) ou à l’exécution de mesures précontractuelles prises à votre demande (par exemple,
            en cas de sollicitation pour un travail) ;<br />
          </span>
          - au respect d’une obligation légale applicable à la Société (par exemple, en matière de facturation, de
          fiscalité, etc.) ;<br />
          - aux fins des intérêts légitimes poursuivis par la Société (ou un destinataire de données) à condition que
          ces intérêts prévalent sur vos libertés et droits fondamentaux.
          <br />
          <br />
          6.2 - Nous vous demandons votre consentement préalable, libre et informé avant de procéder au traitement de
          certaines de vos données à caractère personnel (par exemple, à chaque fois que le traitement de vos données
          impliquent une cession de vos droits à l'image sur des photographies prises des fins de communication interne
          ou externe).
          <br />
          <br />
          6.3 - La fourniture de certaines de vos données à caractère personnel (par exemple, vos données
          d’identification personnelle, vos données d’identification professionnelle, etc.) conditionne en règle la
          conclusion du contrat conclu avec nous pour la fourniture du Logiciel.
          <br />
          <br />
          6.4 - Les conséquences possibles liées à la non fourniture de vos données à caractère personnel pourraient
          inclure notre incapacité à remplir nos obligations en vertu d’un contrat (par exemple, le contrat de
          fourniture du Logiciel) ou une violation dans notre chef d’une ou de plusieurs obligations en vertu des
          législations applicables (par exemple, les législations comptables et fiscales).
        </li>
        <li className="list-item">
          <span className="flow-title">
            D’où proviennent vos données à caractère personnel ?<br />
          </span>
          7.1 - Les données à caractère personnel que nous traitons proviennent des sources suivantes :<br />
          - directement de vous, par exemple par le processus de demande d’offre ;<br />- à partir d’informations
          accessibles publiquement (sur Internet).
        </li>
        <li className="list-item">
          <span className="flow-title">
            Qui a accès à vos données à caractère personnel ?<br />
          </span>
          8.1 - Les destinataires suivants sont susceptibles de recevoir ou d’avoir accès à certaines de vos données à
          caractère personnel (uniquement si cela est nécessaire à l’exercice de leur mission) :<br />
          - les membres de notre personnel chargé de la clientèle ont accès aux données d’identification personnelle,
          aux données d’identification professionnelle et aux données de contact des représentants de nos clients ;
          <br />
          - comme la plupart des services SaaS, un nombre restreint de membres nommément désignés de notre personnel
          technique doit pouvoir accéder à certaines de vos données à caractère personnel accessibles au travers du
          Logiciel lorsque la loi l'exige ou à des fins de correction et/ou de maintenance du Logiciel ;<br />
          - les membres de notre équipe en charge du développement commercial ont accès aux données d’identification
          personnelle, aux données d’identification professionnelle et aux données de contact des représentants de nos
          fournisseurs uniquement dans le cadre de notre gestion des fournisseurs ;<br />
          - nos conseillers juridiques et avocats ont accès à certaines données à caractère personnel des personnes
          concernées dans le cadre d’opérations de restructuration de nos activités ou de litiges.
          <br />
          <br />
          8.2 - Nous confions le traitement de certaines de vos données à caractère personnel à des sous-traitants
          uniquement dans la mesure nécessaire pour réaliser leurs missions et conformément à nos instructions écrites
          et à la Législation sur la Protection des Données Applicable.
          <br />
          <br />
          8.3 - Dans le cas d’une opération de restructuration (par exemple, une opération de financement), nous sommes
          susceptibles de transmettre certaines données à caractère personnel concernant un nombre limité de personnes
          concernées vers une partie tierce impliquée dans l’opération (par exemple, une banque) conformément à la
          Législation sur la Protection des Données Applicable.
        </li>
        <li className="list-item">
          <span className="flow-title">
            Comment gérons-nous nos sous-traitants ?<br />
          </span>
          9.1 - Nous prenons les mesures adéquates pour veiller à ce que nos sous-traitants traitent vos données à
          caractère personnel conformément à la Législation sur la Protection des Données Applicable.
          <br />
          <br />
          9.2 - Nous veillons entre autres à ce que nos sous-traitants s’obligent à ne traiter des données à caractère
          personnel que sur nos instructions, à ne pas engager un autre sous-traitant sans notre autorisation préalable,
          à prendre les mesures techniques et organisationnelles adéquates pour garantir la sécurité des données à
          caractère personnel, à garantir que les personnes autorisées à accéder à des données à caractère personnel
          soient soumis à des obligations adéquates de confidentialité, à renvoyer et/ou supprimer les données à
          caractère personnel qu’ils traitent à la fin de leurs services, à se conformer à des audits et à nous fournir
          une assistance pour assurer le suivi des demandes de personnes concernées quant à l’exercice de leurs droits
          par rapport à leurs données à caractère personnel.
        </li>
        <li className="list-item">
          <span className="flow-title">
            Où traitons-nous vos données à caractère personnel ?<br />
          </span>
          10.1 - Nous veillons à héberger les données de santé des patients exclusivement sur des serveurs situés dans
          le territoire de l’Espace Économique Européen (« EEE »). Certains destinataires de données à caractère
          personnel sont toutefois susceptibles d’être des organisations dont le siège est établi dans un pays situé en
          dehors de l’EEE.Certains destinataires de données à caractère personnel sont susceptibles d’être des sociétés
          dont le siège est établi dans un pays situé en dehors de l’Espace Économique Européen (« EEE ») ou de traiter
          certaines données à caractère personnel depuis un pays situé en dehors de l’EEE.
          <br />
          <br />
          10.2 - Dans le cas où vos données à caractère personnel sont transférées vers des pays situés en dehors de
          l’EEE, la Société veillera à prendre les garanties suivantes :<br />
          - le pays vers lequel les données à caractère personnel sont transférées a bénéficié d’une décision
          d’adéquation de la part de la Commission européenne en vertu de l’article 45 du RGPD et le transfert entre
          dans le champ d’application de ladite décision d’adéquation ;<br />
          - la Société a conclu avec le destinataire des données à caractère personnel un contrat contenant les clauses
          contractuelles types de protection des données à caractère personnel adoptées par la Commission européenne en
          vertu de l’article 47 du RGPD ; ou
          <br />- en cas de transfert vers les États-Unis, le destinataire des données à caractère personnel bénéficie
          d’une certification par rapport au programme EU-US Privacy Shield établi en vertu de l’article 45 du RGPD et
          le transfert entre dans le champ d’application du programme EU-US Privacy Shield.
        </li>
        <li className="list-item">
          <span className="flow-title">
            Quels sont les délais de conservation applicables ?<br />
          </span>
          11.1 - Nous veillons à ce que vos données à caractère personnel ne soient conservées que pour une durée
          n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées.
          <br />
          <br />
          11.2 - La Société utilise les critères suivants pour déterminer la durée de conservation des données à
          caractère personnel en fonction du contexte et des finalités de chacun des traitements :<br />
          - la date de la fin de la licence d’utilisation du Logiciel ou des services fournis par la Société ;<br />
          - la date de la fin de la relation avec le client ou avec le fournisseur ;<br />
          - des raisons de sécurité (par exemple, la sécurité des bâtiments ou de nos systèmes d’information) ;<br />
          - tout différend ou litige en cours ou potentiel avec la personne concernée ;<br />- toute obligation légale
          de conserver ou d’effacer les données à caractère personnel (par exemple, une obligation de conservation
          imposée par une loi comptable ou fiscale).
        </li>
        <li className="list-item">
          <span className="flow-title">
            Quels sont vos droits ?<br />
          </span>
          12.1 - Sous réserve de la Législation sur la Protection des Données Applicable, vous disposez d’un droit
          d’information, d’un droit d’accès à, de rectification de et d’effacement de vos données à caractère personnel,
          du droit de vous opposer à ou de limiter le traitement de vos données à caractère personnel, d’un droit à la
          portabilité des données à caractère personnel et du droit de retirer votre consentement.
          <br />
          <br />
          12.2 - Vous trouverez ci-dessous un tableau décrivant chacun de vos droits plus en détails :<br />
          <u>Droit d'information :</u> Vous avez le droit d’obtenir des informations claires, transparentes et
          compréhensibles sur la manière dont nous traitons vos données à caractère personnel et sur l’exercice de vos
          droits. Ces informations sont contenues dans la Politique. Si elles ne devaient pas être suffisamment claires,
          nous vous invitons à nous contacter (via nos coordonnées reprises dans la Politique).
          <br />
          <u>Droit d'accès :</u>&nbsp;Vous avez le droit d'obtenir la confirmation que des données à caractère personnel
          vous concernant sont ou ne sont pas traitées et, lorsqu'elles le sont, l'accès auxdites données à caractère
          personnel. Vous avez le droit d’obtenir une copie de vos données à caractère personnel, à moins que l’exercice
          de ce droit ne porte atteinte aux droits et libertés d’autrui.
          <br />
          <u>Droit de rectification :</u>&nbsp;Vous avez le droit d'obtenir la rectification des données à caractère
          personnel vous concernant si celles-ci s’avèrent inexactes. Vous avez également le droit d'obtenir que les
          données à caractère personnel vous concernant soient complétées si celles-ci s’avèrent incomplètes.
          <br />
          <u>Droit à l'effacement (« droit à l’oubli ») :</u> Vous avez le droit d’obtenir l’effacement de vos données à
          caractère personnel. Toutefois le droit à l'effacement (ou le « droit à l’oubli ») n’est pas absolu et est
          soumis à des conditions particulières. Nous sommes susceptibles de conserver certaines de vos données à
          caractère personnel dans la mesure autorisée par la loi applicable, et notamment lorsque leur traitement
          demeure nécessaire pour le respect d’une obligation légale à laquelle la Société est soumise ou pour la
          constatation, l’exercice ou la défense d’un droit en justice.
          <br />
          <u>Droit d'opposition au traitement :</u> Vous avez le droit de vous opposer à certains types de traitement
          (par exemple, lorsque le traitement est fondé sur les intérêts légitimes de la Société et que, tenant compte
          de votre situation particulière, vos intérêts ou droits et libertés fondamentaux prévalent).
          <br />
          <u>Droit d'opposition au traitement à des fins de prospection :</u> Vous avez a le droit de vous opposer à
          tout moment au traitement de vos données à caractère personnel lorsque nous traitons ces données à des fins de
          prospection.
          <br />
          <u>Droit à la limitation du traitement :</u> Vous avez le droit d’obtenir la limitation du traitement dans
          certaines circonstances (par exemple lorsque la Société n’a plus besoin de vos données à caractère personnel
          mais celles-ci sont encore nécessaires pour la constatation, l’exercice ou la défense d’un droit en justice).
          <br />
          <u>Droit à la portabilité des données à caractère personnel :</u> Vous avez le droit, dans certaines
          circonstances, de recevoir les données à caractère personnel vous concernant et que vous avez fournies à la
          Société dans un format structuré, couramment utilisé et lisible par une machine et de les transmettre à un
          autre responsable du traitement.
          <br />
          <u>Droit de retirer votre consentement :</u> Si vous avez donné votre consentement à un traitement par la
          Société de vos données à caractère personnel, vous avez le droit de le retirer à tout moment.
          <br />
          <br />
          12.3 - Veuillez noter que vous êtes susceptible d’exercer vos droits vis-à-vis de la Société uniquement dans
          la mesure où nous traitons vos données à caractère personnel en capacité de responsable du traitement. Nous
          ferons suivre au responsable du traitement concerné toute demande d'exercice de vos droits par rapport à vos
          données à caractère personnel si ladite demande concerne un traitement pour lequel nous intervenons en
          capacité de sous-traitant.
          <br />
          <br />
          12.4 - Veuillez adresser toute demande relative à vos droits par rapport à vos données à caractère personnel
          que nous traitons en capacité de responsable du traitement à notre Délégué à la Protection des Données en
          utilisant ses coordonnées reprises dans la Politique. Nous nous engageons à donner suite à votre demande dès
          que matériellement possible et toujours endéans les délais prévus par la Législation sur la Protection des
          Données Applicable. Veuillez noter que nous sommes susceptibles de conserver vos données à caractère personnel
          pour certaines finalités lorsque cela est requis ou autorisé par la loi. Veuillez enfin noter que nous sommes
          susceptibles, en cas de doute quant à votre identité, de vous demander une preuve d’identité afin de prévenir
          tout accès non autorisé à vos données à caractère personnel.
        </li>
        <li className="list-item">
          <span className="flow-title">
            Quel niveau de sécurité assurons-nous ?<br />
          </span>
          13.1 - Nous veillons à prendre des mesures techniques et organisationnelles appropriées pour assurer un niveau
          de sécurité adapté aux risques liés au traitement de vos données à caractère personnel.
          <br />
          <br />
          13.2 - Nous veillons à suivre les meilleures pratiques du secteur pour veiller à ce que les données à
          caractère personnel ne soient pas, de manière accidentelle ou illicite, détruites, perdues, altérées,
          divulguées de manière non autorisée ou accédées de manière non autorisée.
        </li>
        <li className="list-item">
          <span className="flow-title">
            <b>Avez-vous des questions ou des réclamations ?</b>
            <br />
          </span>
          14.1 - Si vous deviez avoir des questions ou des réclamations à propos de la manière dont la Société traite
          vos données à caractère personnel, veuillez les adresser au préalable par courrier à MédiSPRING S.C.R.L., 12
          Cantersteen, 1000 Bruxelles.
          <br />
          <br />
          14.2 - Vous avez le droit d’adresser une réclamation auprès de l’autorité de contrôle compétente. L'autorité
          compétente pour la Belgique est : Autorité de Protection des Données, Rue de la Presse 35, 1000 Bruxelles, +32
          (0)2 274 48 00, contact@apd-gba.be
        </li>
        <li className="list-item">
          <span className="flow-title">Autre chose ?</span>
          <br />
          15.1 - La Société se réserve le droit de mettre à jour la Politique de temps en temps. Nous vous informons de
          tout changement que nous serions amenés à réaliser à la Politique.
          <br />
          <br />
          15.2 - En cas de conflit ou d'incompatibilité entre une disposition de la Politique et une disposition d'une
          autre politique ou d’un autre document de la Société relatif au traitement de données à caractère personnel,
          la disposition de la Politique prévaudra.
        </li>
      </ol>
    </div>
  );
}
