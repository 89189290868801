import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl-be';
import 'dayjs/locale/de';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { TRANSLATIONS_FR } from '../locales/fr';
import { TRANSLATIONS_NL } from '../locales/nl';
import { TRANSLATIONS_DE } from '../locales/de';
import { TEMPLATES_PATH } from './constants';
import dayjs from 'dayjs';

export enum Languages {
  FR = 'fr',
  NL = 'nl-BE',
  DE = 'de',
}

const localResources = {
  [Languages.FR]: {
    translation: TRANSLATIONS_FR,
  },
  [Languages.NL]: {
    translation: TRANSLATIONS_NL,
  },
  [Languages.DE]: {
    translation: TRANSLATIONS_DE,
  },
};

export const initTranslations = (lang: string = Languages.FR) => {
  dayjs.locale(lang);
  i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
      lng: lang,
      fallbackLng: Languages.FR,
      preload: Object.values(Languages),
      ns: ['translation', 'emails'],
      defaultNS: 'translation',
      partialBundledLanguages: true,
      interpolation: {
        escapeValue: false, // Removing URL encoding so links work in yahoo mail
      },
      backend: {
        backends: [resourcesToBackend(localResources), HttpApi],
        backendOptions: [{}, { loadPath: TEMPLATES_PATH }],
      },
    });
};

/**
 * As it is more useful to test if the dom features the right labels than the texts themselves (which would break the test on every copy change), inits the translations with empty labels.
 */
export const initEmptyTranslationsForTests = () => {
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: { fr: {} },
      lng: 'fr',
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
};
