import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="wrap">
        <div>
          <h4>© 2024 Medispring</h4>
          <address className="small-text">
            <p>
              Medispring SCRL <br />
              Boucle Odon Godart, 7<br />
              B- 1348 Louvain-a-Neuve
            </p>

            <p>
              Numéro d'entreprise:
              <br />
              0697.560.553
            </p>
          </address>
        </div>

        <div style={{ flex: 1 }}>
          <h4>{t('FOOTER.LEGAL')}</h4>
          <p className="small-text">
            <a href="https://www.medispring.be/politique-de-vie-priv%C3%A9e.html" target="_blank">
              {t('FOOTER.PRIVACY')}
            </a>
          </p>
          <p className="small-text">
            <a href="/terms" target="_blank">
              {t('FOOTER.TOU')}
            </a>
          </p>
        </div>

        <div>
          <h4>{t('FOOTER.HELP')}</h4>
          <p className="small-text">
            <a href="/faq" target="_blank">
              {t('FOOTER.FAQ')}
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
