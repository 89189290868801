export interface Screen {
  name: string;
  condition?: (state: any) => boolean;
}

let history: Screen[];
let screenList: Screen[];

const missingScreenListError = 'You should first provide a screenList (Screens[]) by calling initScreenList().';

export const initScreenList = (flowScreenList: Screen[]) => {
  screenList = flowScreenList;
  history = [];
};

export const gotoFirstValid = (state: any): string | undefined => {
  history = [];
  return gotoNextValid(state);
};

export const gotoNextValid = (state: any): string | undefined => {
  if (!screenList) {
    throw missingScreenListError;
  }
  const currenScreenIndex: number = history.length
    ? screenList.findIndex(({ name }) => name === history[history.length - 1].name)
    : -1;
  const nextScreen = screenList.find(
    ({ condition }, index) => index > currenScreenIndex && (condition ? condition(state) : true),
  );
  if (nextScreen) {
    history.push(nextScreen);
  }
  return getCurrent();
};

export const gotoScreenName = (screenName: string) => {
  if (!screenList) {
    throw missingScreenListError;
  }
  const nextScreen = screenList.find(({ name }) => name === screenName);
  if (nextScreen) {
    history.push(nextScreen);
  }
  return getCurrent();
};

export const getCurrent = (): string => {
  if (!screenList) {
    throw missingScreenListError;
  }
  return history.length ? history[history.length - 1].name : screenList[0].name;
};

export const gotoPrevious = (): string => {
  if (!screenList) {
    throw missingScreenListError;
  }
  if (history.length > 1) {
    history.pop();
  }
  return getCurrent();
};
