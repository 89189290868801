import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FlowState } from '../AppointmentFlow';
import { getTimeSlots } from '../../services/apiService';
import { getGroupedTimeslots, GroupedTimeslot } from './service/AppointmentFlowServices';
import { HistorySteps } from './components/HistorySteps';
import dayjs from 'dayjs';
import { API_TIME_FORMAT } from '../../services/constants';
import { ClassNames, ScreenName } from './service/types';
import Loading from '../common/Loading';
import { selectAppointmentTypesByPlaceId } from '../../services/utils';

interface Props {
  flowState: FlowState;
  onSubmit: (property: { timeslot: number }) => void;
  gotoScreen: (screenName: ScreenName, newState: FlowState) => void;
}
const MAX_SLOTS_BY_CALL = 100;

const TimeslotChoice = function ({
  flowState,
  flowState: { groupId, user, healthcareParty, appointmentTypes, appointmentType, placeId, isNewPatient },
  onSubmit,
  gotoScreen,
}: Props) {
  appointmentType = appointmentType || selectAppointmentTypesByPlaceId(appointmentTypes!, placeId!, true)[0]; // select the only available appointmentType if the user did not select any because there was only one available.

  const [timeSlots, setTimeSlots] = useState<number[] | undefined>(undefined);

  const [groupedTimeSlots, setGroupedTimeSlots] = useState<GroupedTimeslot[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moreSlotsAvailable, setMoreSlotsAvailable] = useState<boolean>(true);

  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      const slots = await getTimeSlots(
        groupId!,
        user?.id!,
        healthcareParty?.id!,
        appointmentType?.calendarItemTypeId!,
        placeId!,
        !!isNewPatient,
      );
      setTimeSlots(slots);
      setGroupedTimeSlots(getGroupedTimeslots(slots));
    })();
  }, [flowState]);

  const loadMore = async () => {
    setIsLoading(true);
    getTimeSlots(
      groupId!,
      user?.id!,
      healthcareParty?.id!,
      appointmentType?.calendarItemTypeId!,
      placeId!,
      !!isNewPatient,
      timeSlots ? timeSlots[timeSlots.length - 1] + 100 : undefined,
    ).then(newSlots => {
      setIsLoading(false);
      if (newSlots.length === 0) {
        setMoreSlotsAvailable(false);
      } else {
        const slots = timeSlots ? timeSlots.concat(newSlots) : newSlots;
        setTimeSlots(slots);
        setGroupedTimeSlots(getGroupedTimeslots(slots));
        if (newSlots.length < MAX_SLOTS_BY_CALL) {
          setMoreSlotsAvailable(false);
        }
      }
    });
  };

  const getHistory = () => (
    <HistorySteps
      flowState={flowState}
      untilScreen={ScreenName.CALENDAR_ITEM_TYPE_CHOICE}
      gotoScreen={gotoScreen}
      selections={{ appointmentType }}
    />
  );

  if (!groupedTimeSlots) {
    return (
      <div>
        <ol className="ordered-list">{getHistory()}</ol>
        <Loading />
      </div>
    );
  }
  return (
    <ol className="ordered-list">
      {getHistory()}
      <li className="list-item transition-animation">
        <h3 className="screen-title">{t('FLOW.TIMESLOT_CHOICE.TITLE')}</h3>
        <ul className={ClassNames.flowContent}>
          {groupedTimeSlots.length === 0 ? (
            <p>{t('FLOW.TIMESLOT_CHOICE.NO_TIMESLOTS')}</p>
          ) : (
            groupedTimeSlots.map((groupedTimeslot, index) => {
              return (
                <li key={index}>
                  <h4 className="button-grid-title">{dayjs(groupedTimeslot.day, 'YYYYMMDD').format('dddd D MMM')}:</h4>
                  <ul className="button-grid">
                    {groupedTimeslot.timeslots.map((timeslot: number, tsIndex: number) => {
                      return (
                        <li key={tsIndex} className="button-grid-item">
                          <button className="button" onClick={() => onSubmit({ timeslot })}>
                            {dayjs(String(timeslot), API_TIME_FORMAT).format('HH:mm')}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })
          )}
        </ul>
        {isLoading ? (
          <Loading />
        ) : moreSlotsAvailable ? (
          <button
            className="button"
            style={{ marginTop: 'var(--marginL)', backgroundColor: 'var(--neutral-light)' }}
            onClick={() => loadMore()}
          >
            {t('FLOW.TIMESLOT_CHOICE.SHOW_MORE')}
          </button>
        ) : (
          <p style={{ textAlign: 'center', marginTop: 'var(--marginL)' }}>{t('FLOW.TIMESLOT_CHOICE.NO_MORE')}</p>
        )}
      </li>
    </ol>
  );
};

export default TimeslotChoice;
