export const TRANSLATIONS_DE = {
  UI: {
    INFORMATION: 'Informationen',
    PLACE: 'Ort',
    LOADING: 'Laden...',
    SAVING: 'Ihren Termin speichern...',
    HEADER: {
      APP_NAME: 'Online-Terminplaner',
      POWERED_BY: 'Bereitgestellt von Medispring',
    },
    ERROR: {
      TITLE: 'Wählen Sie einen Praktiker aus',
      SUBTITLE: '',
      NO_AGENDA: 'Kein Terminplan unter dieser Adresse',
      HCPS_IN_GROUP:
        'Mehrere Praktiker sind an dieser Adresse verfügbar. Mit wem möchten Sie einen Termin vereinbaren?',
    },
  },
  TOOLTIP: {
    REMEMBER_ME:
      'Wenn Sie diese Option ankreuzen, werden Ihre Informationen auf diesem Computer gespeichert, so dass Sie sie bei Ihrem nächsten Besuch nicht erneut eingeben müssen. Wählen Sie diese Option nicht, wenn Sie diesen Computer mit anderen teilen.',
  },
  BUTTONS: {
    START_APPOINTMENT: 'Termin vereinbaren',
    CANCEL: 'Abbrechen',
    NEXT: 'Weiter',
    CONFIRM: 'Bestätigen',
    NEW_APPOINTMENT: 'Neuen Termin vereinbaren',
    OTHER_APPOINTMENT: 'Anderer Termin',
    CHANGE: 'Ändern',
    VIEW_ITINERARY: 'Route anzeigen',
  },
  FLOW: {
    NEW_APPOINTMENT: 'Neuer Termin',
    NEW_PATIENT_QUESTION: {
      TITLE: 'Haben Sie diesen Praktiker bereits konsultiert?',
      YES: 'Ja',
      NO: 'Nein',
    },
    NEW_PATIENT_REJECTION: {
      TEXT: '<p>Leider reserviert dieser Praktiker Online-Termine nur für bereits betreute Patienten.</p>',
    },
    CALENDAR_ITEM_TYPE_CHOICE: {
      TITLE: 'Wählen Sie einen Termin-Typ: ',
    },
    TIMESLOT_CHOICE: {
      TITLE: 'Wählen Sie eine Uhrzeit: ',
      NO_TIMESLOTS:
        'Keine Online-Verfügbarkeit, bitte kontaktieren Sie das Sekretariat, um einen Termin zu vereinbaren.',
      SHOW_MORE: 'Mehr Zeiten anzeigen',
      NO_MORE:
        '😥 Keine weiteren Online-Verfügbarkeiten nach diesem Datum. Bitte kontaktieren Sie uns, um einen Termin zu vereinbaren.',
    },
    IDENTIFICATION: {
      ERROR: 'Bitte füllen Sie korrekt aus: {{missingFields}}.',
      TITLE: 'Ihre Kontaktdaten: ',
      FIRSTNAME: 'Vorname',
      LASTNAME: 'Nachname',
      EMAIL: 'E-Mail',
      PHONE: 'Telefon',
      REASON: 'Mitteilungen (optional)',
      REMEMBER_ME: 'Meine Angaben speichern',
      POLICY:
        "Mit dem Klicken auf {{action}} erklären Sie sich mit den <a href='/terms' target='_blank'>Nutzungsbedingungen</a> einverstanden",
      REVIEW:
        'Ihre Informationen werden sicher übertragen. Die Online-Terminvereinbarung ersetzt keine Beratung. Durch die Bestätigung dieses Termins verpflichten Sie sich zur Einhaltung. Bitte denken Sie daran, so früh wie möglich abzusagen, falls etwas dazwischenkommt',
      WARNING: 'Achtung: Es gibt noch einen Schritt, um Ihren Termin zu bestätigen.',
    },
    VERIFICATION_CODE: {
      CALL_TO_ACTION:
        'Ein 6-stelliger Code wurde an <strong>{{email}}</strong> gesendet.<br/>Geben Sie diesen Code unten ein, um Ihre E-Mail-Adresse zu überprüfen und Ihre Terminanfrage zu bestätigen.',
    },
    SAVE_APPOINTMENT: {
      FROM_MEDISPRING: 'Über Medispring: ',
      APPOINTMENT_CONFIRMATION: 'Terminbestätigung',
      CONFIRMATION:
        'Ihr Termin mit <strong>{{name}}</strong> am <strong>{{day}} um {{time}}</strong> ist bestätigt. Vergessen Sie nicht Ihre Ausweisdokumente.</p><p>Danke und bis bald!',
    },
    CANCEL_APPOINTMENT: {
      HEADER: 'Diesen Termin absagen?',
      BODY: 'Sie sind dabei, Ihren Termin zu löschen. Um Ihren Termin abzusagen, klicken Sie auf die Schaltfläche unten.',
      EMAIL_SUBJECT: 'Stornierung Ihres Termins mit {{name}}',
      CONFIRMATION_HEADER: 'Termin storniert',
      CONFIRMATION_BODY:
        'Ihr Termin wurde erfolgreich storniert. Vielen Dank, dass Sie uns rechtzeitig informiert haben. Wir danken Ihnen für Ihr Vertrauen.',
    },
    REMIND_APPOINTMENT: {
      EMAIL_SUBJECT: 'Terminerinnerung: ',
    },
  },
  ERRORS: {
    ID_CODE_INVALID: 'Der Code muss 6 Ziffern enthalten.',
    ID_CODE_WRONG:
      'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie, ob der von Ihnen eingegebene Code mit dem Code übereinstimmt, den Sie per E-Mail erhalten haben.',
    GENERIC: 'Ein technischer Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    IS_MEDISPRING_USER:
      'Diese E-Mail-Adresse kann nicht verwendet werden, um einen Termin mit dieser Praxis zu vereinbaren, da sie bereits mit einem Medispring-Nutzenden im Gesundheitswesen verbunden ist. Bitte verwenden Sie eine andere E-Mail-Adresse.',
  },
  FOOTER: {
    LEGAL: 'Rechtliche Hinweise',
    PRIVACY: 'Datenschutzrichtlinie',
    TOU: 'Nutzungsbedingungen',
    HELP: 'Brauchen Sie Hilfe? 📖',
    FAQ: 'Häufig gestellte Fragen',
  },
  SPECIALITIES: {
    ADMINISTRATION: 'Verwaltung',
    CARE_GIVER: 'Pflegehelfer',
    ANATOMOPATHOLOGY: 'Anatomopathologie',
    ANESTHESIOLOGY: 'Anästhesiologie',
    SOCIAL_WORKER: 'Sozialarbeiter',
    PHARMA_ASSISTANT: 'Pharmazeutisch-technischer Assistent',
    AUDIOLOGIST: 'Audiologe',
    BACTERIOLOGY: 'Bakteriologie',
    TRUSS_ORTHOTIST: 'Bandagist-Orthopädist',
    CLINICAL_BIOLOGY: 'Klinische Biologie',
    CARDIOLOGY: 'Kardiologie',
    GENERAL_SURGERY: 'Allgemeinchirurgie',
    MAXILLOFACIAL_SURGERY: 'Kiefer-Gesichtschirurgie',
    PLASTIC_SURGERY: 'Plastische Chirurgie',
    STOMATOLOGICAL_SURGERY: 'Stomatologische Chirurgie',
    CARDIOVASCULAR_SURGERY: 'Herz-Kreislauf-Chirurgie',
    ABDOMINAL_SURGERY: 'Abdominalchirurgie',
    DENTISTRY: 'Zahnheilkunde',
    DERMATOLOGY: 'Dermatologie',
    DIETETIC: 'Diätetik',
    ENDOCRINOLOGY: 'Endokrinologie',
    OCCUPATIONAL_THERAPIST: 'Ergotherapeut',
    GASTROENTEROLOGY: 'Gastroenterologie',
    GENETIC: 'Genetik',
    GERIATRICS: 'Geriatrie',
    GYNECOLOGY: 'Gynäkologie/Obstetrik',
    HEMATOLOGY: 'Hämatologie',
    NURSE: 'Krankenpfleger',
    NURSE_EDUCATOR: 'Pflegepädagoge',
    PHYSIOTHERAPY: 'Physiotherapie',
    SPEECH: 'Logopädie',
    ACUTE_MEDICINE: 'Akutmedizin',
    GENERAL_MEDICINE: 'Allgemeinmedizin',
    persphysician: 'Allgemeinmediziner',
    INTERNAL_MEDICINE: 'Innere Medizin',
    NUCLEAR_MEDICINE: 'Nuklearmedizin',
    PHYSICAL_MEDICINE: 'Physikalische Medizin/Physiotherapie',
    EXPERTISE_MEDICINE: 'Begutachtungsmedizin',
    INSURANCE_MEDICINE: 'Versicherungsmedizin',
    TRAVEL_MEDICINE: 'Reisemedizin',
    NEONATOLOGY: 'Neonatologie',
    NEPHROLOGY: 'Nephrologie',
    NEUROSURGERY: 'Neurochirurgie',
    NEUROLOGY: 'Neurologie',
    NEUROPEDIATRICS: 'Neuropädiatrie',
    NEUROPSYCHIATRY: 'Neuropsychiatrie',
    NUTRITIONIST: 'Ernährungsberater',
    ONCOLOGY: 'Onkologie',
    OPHTHALMOLOGY: 'Augenheilkunde',
    ORTHOPEDICS: 'Orthopädie',
    ORTHOPTISTE: 'Orthoptist',
    OTORHINOLARYNGOLOGY: 'Hals-Nasen-Ohrenheilkunde',
    PEDIATRICS: 'Pädiatrie',
    PHARMACOLOGY: 'Pharmakologie',
    RESPIRATORY: 'Pneumologie',
    PODIATRIST: 'Podologe',
    PUBLIC_HEALTH_PREVENTION: 'Gesundheitsvorsorge',
    PROSTHETIST: 'Prothetiker',
    PSYCHIATRY: 'Psychiatrie',
    MEDICAL_PSYCHOLOGY: 'Medizinische Psychologie',
    RADIODIAGNOSTICS: 'Radiodiagnostik/Medizinische Bildgebung',
    RADIOTHERAPY: 'Strahlentherapie',
    RESUSCITATION: 'Wiederbelebung',
    RHEUMATOLOGY: 'Rheumatologie',
    MIDWIFE: 'Hebamme',
    STOMATOLOGY: 'Stomatologie',
    EMERGENCY: 'Notfallmedizin',
    UROLOGY: 'Urologie',
    VIROLOGY: 'Virologie',
    TOXICOLOGY: 'Toxikologie',
    TRANSPORT: 'Krankentransport',
    ALGOLOGY: 'Algologie',
    GUARDHOUSE: 'Wachstube',
    REVALIDATION: 'Rehabilitation',
    SENOLOGY: 'Senologie',
    DIABETOLOGY: 'Diabetologie',
    INFECTIOLOGY: 'Infektiologie',
    KINESIOLOGY: 'Kinesiologie',
    PHYSIOTHERAPYMICRO: 'Physiotherapie: Mikrokinetik',
    OSTHEAOPATIA: 'Osteopathie',
    PSYCHOTHERAPY: 'Psychotherapie',
    OTHER: 'Andere',
    HYPNOSIS: 'Hypnose',
    PERSPHYSICIAN: 'Arzt',
    NO_SPECIALITY: 'Keine Spezialität',
  },
};
