// SITE ID
import { ERROR } from '../components/appointment-flow-screens/service/types';

export const FATHOM_SITE_ID = 'JFWGOYJF';

// EVENTS
export enum FathomEvent {
  FLOW_START = '5M4TLZQT',
  FLOW_CLOSE = 'H1H4OVOY',
  FLOW_SLOT_SELECTED = 'ASJYCAMK',
  FLOW_RETURNING_CONFIRMED = 'M4BXK79W',
  FLOW_CONFIRMATION_CODE_SENT = 'KZRNDJO4',
  FLOW_FORM_SENT = '6T5ZUFOR',
  FLOW_APPOINTMENT_SAVED = 'NXHSPSGA',
  FLOW_TYPE_SELECTED = 'VFEGWPAK',
}

export const FathomError: Partial<Record<ERROR, string>> = {
  ID_SERVER: '2YGEX88G',
  ID_CODE_INVALID: 'AFOLPAB1',
  ID_CODE_WRONG: '3RSVQUHC',
  PATIENT_CONFIGURATION: 'AFSOVPHQ',
  SLOT_NOT_AVAILABLE: 'FCZQVQGY',
  SAVE_APPOINTMENT: 'OJ5LF0GX',
  RECAPTCHA: 'P7YXQ71R',
};
