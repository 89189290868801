import React, { useEffect, useState } from 'react';
import {
  formatHealthcarePartyName,
  getAgendaConfiguration,
  getUniquePlacesFromAppointmentTypesAndHCP,
  selectAddressFromHealthcareParty,
  selectPhoneNumberFromHealthcareParty,
} from '../services/utils';
import { getAppointmentTypes, getUserAndHealthcareParty } from '../services/apiService';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/nl-be';
import { Address, AppointmentTypeAndPlace, HealthcareParty, User, UserAndHealthcareParty } from '@icure/api';
import { useParams } from 'react-router';
import ErrorScreen, { Link } from '../components/ErrorScreen';
import Loading from '../components/common/Loading';
import AppointmentFlow from '../components/AppointmentFlow';
import { useTranslation } from 'react-i18next';

export interface UrlParams {
  groupId: string;
  healthcarePartyId: string;
  calendarItemId?: string;
}

function HealthcarePartyPage() {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<{
    appointmentTypes: Array<AppointmentTypeAndPlace>;
    selectedPlaceId: string | null;
    user?: User;
    healthcareParty?: HealthcareParty;
    address?: Address;
    telecomNumber?: string;
    groupId?: string;
    error?: string;
    links?: Link[];
  }>({
    user: undefined,
    healthcareParty: undefined,
    address: undefined,
    telecomNumber: undefined,
    error: undefined,
    appointmentTypes: [],
    selectedPlaceId: null,
    groupId: undefined,
  });

  const { groupId, healthcarePartyId }: UrlParams = useParams() as unknown as UrlParams;

  useEffect(() => {
    (async () => {
      if (!state.healthcareParty && !state.error) {
        try {
          const { healthcareParty, user } = await getUserAndHealthcareParty(groupId, healthcarePartyId);

          const config = getAgendaConfiguration(healthcareParty!);

          i18n.changeLanguage(config.interfaceDefaultLanguage);
          dayjs.locale(config.interfaceDefaultLanguage);

          const appointmentTypes: Array<AppointmentTypeAndPlace> = await getAppointmentTypes(groupId, user?.id!);
          const uniquePlacesList = getUniquePlacesFromAppointmentTypesAndHCP(appointmentTypes, healthcareParty);
          setState({
            ...state,
            healthcareParty,
            user,
            appointmentTypes: appointmentTypes,
            selectedPlaceId: uniquePlacesList[0].id!,
            groupId,
            address: selectAddressFromHealthcareParty(healthcareParty!),
            telecomNumber: selectPhoneNumberFromHealthcareParty(healthcareParty!),
          });
        } catch (error: any) {
          setState({
            ...state,
            error: error.message,
            links: makeLinksFromError(error),
          });
        }
      }
    })();
  }, [state, setState]);

  if (state.error) {
    return <ErrorScreen message={state.error} links={state.links} />;
  }

  if (!state.healthcareParty || !state.user) {
    return (
      <div className="main-container wrap">
        <Loading />
      </div>
    );
  }

  function makeLinksFromError(error: Error) {
    if (error.cause && (error.cause as unknown as Link[]).length) {
      return (error.cause as unknown as UserAndHealthcareParty[]).map(uh => {
        return { label: formatHealthcarePartyName(uh.healthcareParty!), url: `/${groupId}/${uh.healthcareParty?.id}` };
      });
    }
    return [];
  }

  function getInformationText() {
    const config = getAgendaConfiguration(state?.healthcareParty!);

    const lang =
      i18n.language == 'nl-BE' ? 'nl' : ((i18n.language as 'fr' | 'de') ?? config.interfaceDefaultLanguage ?? 'fr');

    const informationText = config[lang] as string;
    if (informationText) {
      return (
        <div className="information-box">
          <h3 className="medium-title">{t('UI.INFORMATION')}</h3>
          <div dangerouslySetInnerHTML={{ __html: informationText }}></div>
        </div>
      );
    }
    return;
  }

  if (!state.healthcareParty || !state.appointmentTypes) {
    return <Loading />;
  }

  return (
    <div className="main wrap">
      <div className="flow">
        <AppointmentFlow
          healthcareParty={state.healthcareParty}
          user={state.user}
          appointmentTypes={state.appointmentTypes}
          placeId={state.selectedPlaceId!}
          groupId={state.groupId!}
        />
      </div>

      {getInformationText()}
    </div>
  );
}

export default HealthcarePartyPage;
