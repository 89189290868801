export interface IcureTokens {
  token: string;
  refreshToken: string;
}

export class Credentials {
  constructor(json: JSON | any) {
    Object.assign(this as Credentials, json);
  }

  username?: string;
  password?: string;
  ehpassword?: string;
  tokenId?: string;
  keyStoreId?: string;
  groupId?: string;
  userId?: string;
  icureToken?: IcureTokens;

  public getFullUsername(groupId: string = '', userId: string = ''): string {
    if (groupId && userId) {
      return `${groupId}/${userId}`;
    }
    return this.groupId && this.userId ? `${this.groupId}/${this.userId}` : (this.username ?? '');
  }
}
