import {
  AppointmentTypeAndPlace,
  CalendarItem,
  EntityWithDelegationTypeName,
  HealthcareParty,
  IccAnonymousAccessApi,
  Patient as IcurePatient,
  retry,
  SecureDelegation,
  User,
  UserAndHealthcareParty,
} from '@icure/api/';

import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {ERROR, MailData, Patient, TokenByGroup} from '../components/appointment-flow-screens/service/types';
import {FlowState} from '../components/AppointmentFlow';
import {
  buildCalendarItem,
  cancelReminder,
  DEFAULT_PLACE_ID,
  formatAddress,
  formatHealthcarePartyName,
  formatTelecoms,
  getAgendaConfiguration,
  getHost,
  getMillisecondsBetweenNowAndDate,
  getNow,
  getOneYearFrom,
  PATIENT_APP_TAG,
  sendEmail,
} from './utils';
import {getAppId} from '../components/service/localStorage';
import i18n from 'i18next';
import {getApi} from './apiProviderService';
import {IccUserXApi, LoginCredentials} from '@icure/api';
import {API_TIME_FORMAT, ICURE_HOST, MESSAGE_HOST, PROCESS_ID, SPEC_ID} from './constants';
import {UserGroup} from '@icure/api/icc-api/model/UserGroup';
import {IcureApiService} from "./icure-api.service";
import {CryptoHelper} from "../helpers/crypto.helper";
import {Credentials} from "../model/credentials.model";
import {MapStringOf} from "../model/rsa.model";

dayjs.extend(customParseFormat);

const aaApi = new IccAnonymousAccessApi(ICURE_HOST, {});
const SIX_MONTHS: number = 0.5 * 365 * 24 * 60 * 60;

let icurePatientPromise: Promise<IcurePatient | undefined | null> | undefined;
let patientUserPromise: Promise<User | null | undefined> | undefined;
let healthcarePartyAncestorPromise: Promise<HealthcareParty> | undefined;

const getAncestor = async (hcpartyApi: any, healthcareParty: HealthcareParty): Promise<HealthcareParty> => {
  if (healthcareParty.parentId) {
    return await getAncestor(
      hcpartyApi,
      await getFullHealthcareParty(hcpartyApi, healthcareParty.parentId),
    );
  }
  return healthcareParty;
};

export const gethealthcarepartyAncestor = async (
  hcpartyApi?: any,
  healthcareParty?: HealthcareParty,
): Promise<HealthcareParty> => {
  if (healthcarePartyAncestorPromise) {
    return healthcarePartyAncestorPromise;
  }
  if (!hcpartyApi || !getFullHealthcareParty) {
    throw new Error('You need to provide hcpartyApi and a base healthcareParty as ancestor is not yet cached');
  }

  return (healthcarePartyAncestorPromise = getAncestor(
    hcpartyApi,
    healthcareParty!.parentId
      ? healthcareParty!
      : await getFullHealthcareParty(hcpartyApi, healthcareParty!.id!),
  ));
};

const getUserApi = async (username: string, password: string): Promise<IccUserXApi | undefined> =>
  (await getApi(new Credentials({username,password,}))).userApi;

export const getUserAndHealthcareParty = async (
  groupId: string,
  healthCarePartyId: string,
): Promise<UserAndHealthcareParty> => {
  const list: UserAndHealthcareParty[] | undefined = await aaApi.listHealthcarePartiesInGroup(groupId);
  const result: UserAndHealthcareParty | undefined = list.find(
    ({ healthcareParty }) => healthcareParty?.id === healthCarePartyId,
  );
  if (!result) {
    throw new Error(i18n.t('UI.ERROR.HCPS_IN_GROUP') as string, { cause: list as any });
  }
  return result;
};

export const getUserAndHealthcareParties = async (groupId: string): Promise<UserAndHealthcareParty[]> => {
  const list: UserAndHealthcareParty[] | undefined = await aaApi.listHealthcarePartiesInGroup(groupId);
  if (!list) {
    throw new Error(i18n.t('UI.ERROR.HCPS_IN_GROUP') as string, { cause: list });
  }
  return list;
};

export const getAppointmentTypes = async (groupId: string, userId: string): Promise<AppointmentTypeAndPlace[]> => {
  return retry(() => aaApi.listAppointmentTypesForUser(groupId, userId, getNow(), getOneYearFrom(getNow())), 3, 500);
};

export const getTimeSlots = async (
  groupId: string,
  userId: string,
  hcpId: string,
  calendarItemTypeId: string,
  placeId: string,
  isNewPatient: boolean,
  startTime?: number,
) => {
  return retry(
    () =>
      aaApi.getAvailabilitiesByPeriodAndCalendarItemTypeId(
        groupId,
        userId,
        calendarItemTypeId,
        isNewPatient,
        startTime ?? getNow(),
        getOneYearFrom(startTime ?? getNow()),
        hcpId,
        placeId === DEFAULT_PLACE_ID ? undefined : placeId,
      ),
    3,
    500,
  );
};

export const checkTimeSlotAvailability = async (
  groupId: string,
  userId: string,
  hcpId: string,
  { duration, placeId, calendarItemTypeId }: AppointmentTypeAndPlace,
  isNewPatient: boolean,
  timeslot: number,
) => {
  const endTime = parseInt(
    dayjs(timeslot.toString(), API_TIME_FORMAT).add(duration!, 'minute').format(API_TIME_FORMAT),
  );
  return (
    await retry(
      () =>
        aaApi.getAvailabilitiesByPeriodAndCalendarItemTypeId(
          groupId,
          userId,
          calendarItemTypeId!,
          isNewPatient,
          timeslot,
          endTime,
          hcpId,
          placeId,
        ),
      3,
      500,
    )
  ).includes(timeslot);
};

export const sendConfirmationEmail = async (
  { patient, patientToken, healthcareParty, groupId, timeslot }: FlowState,
  calendarItem: CalendarItem,
  b64IcureAccessControlKey: string | undefined
) => {
  const { email } = patient!;
  if (!patientToken) {
    throw new Error(`No token found in patient for groupId: ${groupId}.  
        Tokens only available for groupIds: ${groupId}.`);
  }
  const { id: userId }: User = (await getUser(patient!, groupId!))!;
  const calendarItemToken: string | undefined = (
    await getNewTokens(
      patient!,
      patientToken,
      groupId!,
      `ci-${calendarItem.id}`,
      getMillisecondsBetweenNowAndDate(String(timeslot)),
    )
  ).find(item => item.groupId === groupId)!.token;
  if (!calendarItemToken) {
    throw new Error('No token found');
  }
  const healthCarePartyUrl: string = `${getHost()}${encodeURI(groupId!)}/${encodeURI(healthcareParty?.id!)}`;
  const calendarItemUrl: string = `${healthCarePartyUrl}/${calendarItem.id}/${userId}/${calendarItemToken}?accessControlKey=${b64IcureAccessControlKey || ''}`;
  const longDate: String = dayjs(String(timeslot), API_TIME_FORMAT).format('ddd DD/MM, HH:mm');
  const month: string = dayjs(String(timeslot), API_TIME_FORMAT).format('MMM');
  const day: string = dayjs(String(timeslot), API_TIME_FORMAT).format('DD');
  const hcpName: string = formatHealthcarePartyName(healthcareParty!);
  const address: string = formatAddress(calendarItem?.address!);
  const telecoms: string = formatTelecoms(calendarItem?.address!);

  const addressWithTelecoms = `${address} ${telecoms}`;

  const emailSentResult: any = await sendEmail(
    email!,
    `${i18n.t('FLOW.SAVE_APPOINTMENT.APPOINTMENT_CONFIRMATION')}: ${formatHealthcarePartyName(healthcareParty!)} - ${dayjs(String(timeslot), API_TIME_FORMAT).format('DD MMM YY - HH:mm')}`,
    i18n.t('emails:confirmation', {
      calendarItemUrl,
      healthCarePartyUrl,
      longDate,
      month,
      day,
      hcpName,
      address: addressWithTelecoms,
    }),
    patientToken,
  ).catch(e => false);

  // Send a reminder email
  const config = getAgendaConfiguration(healthcareParty!);
  const reminderTime = dayjs(String(timeslot)).subtract(
    config.sendPatientEmailReminderDelayBeforeAppointment ?? 24,
    'hours',
  );

  let reminderEmailSentResult: any = false;
  const mustSendReminderEmail: boolean = !!config.sendPatientEmailReminder && reminderTime.isAfter(dayjs());

  if (mustSendReminderEmail) {
    reminderEmailSentResult = await sendEmail(
      email!,
      `${i18n.t('FLOW.REMIND_APPOINTMENT.EMAIL_SUBJECT')} ${formatHealthcarePartyName(healthcareParty!)} - ${dayjs(String(timeslot), API_TIME_FORMAT).format('DD MMM YY - HH:mm')}`,
      i18n.t('emails:reminder', {
        calendarItemUrl,
        healthCarePartyUrl,
        longDate,
        month,
        day,
        hcpName,
        address,
      }),
      patientToken,
      reminderTime.valueOf(),
      //dayjs().add(2,'minutes').valueOf(), // for development purposes
      calendarItem.id,
    ).catch(e => false);
  }

  if (emailSentResult !== 'ok') throw ERROR.EMAIL_NOT_SENT;
  if (mustSendReminderEmail && !reminderEmailSentResult?._id) throw ERROR.REMINDER_EMAIL_NOT_SENT;
};

export const sendCancellationConfirmationEmail = async (
  { patient, patientToken, healthcareParty, groupId, timeslot }: FlowState,
  confirmationId: string,
) => {
  const user = await patientUserPromise;
  if (!patientToken) {
    throw new Error(`No token found in patient for groupId: ${groupId}.  
        Tokens only available for groupIds: ${groupId}}.`);
  }
  const healthCarePartyUrl: string = `${getHost()}${encodeURI(groupId!)}/${encodeURI(healthcareParty?.id!)}`;
  const longDate: String = dayjs(String(timeslot), API_TIME_FORMAT).format('ddd DD/MM, HH:mm');
  const hcpName: string = formatHealthcarePartyName(healthcareParty!);
  if (confirmationId) cancelReminder(confirmationId, user?.email!, patientToken);
  await sendEmail(
    user?.email!,
    i18n.t('FLOW.CANCEL_APPOINTMENT.EMAIL_SUBJECT', { name: formatHealthcarePartyName(healthcareParty!) }),
    i18n.t('emails:cancellation', {
      healthCarePartyUrl,
      longDate,
      hcpName,
    }),
    patientToken,
  );
};

export const sendValidationEmail = async (
  recaptchaToken: string,
  { patient, healthcareParty, groupId }: FlowState,
  requestId: string,
) => {
  const { firstName, lastName, email, phoneNumber } = patient!;
  const postData: MailData = {
    'g-recaptcha-response': recaptchaToken,
    firstName: firstName!,
    lastName: lastName!,
    emailAddress: email!,
    mobilePhone: phoneNumber!,
    group: groupId!,
    from: email!,
    hcp: formatHealthcarePartyName(healthcareParty!),
    hcpId: healthcareParty?.id!,
  };
  const { ok, statusText }: Response = await fetch(
    `${MESSAGE_HOST}/${SPEC_ID}/process/${PROCESS_ID[i18n.language ?? 'fr']}/${requestId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    },
  );
  if (!ok) {
    throw new Error(statusText);
  }
};

export const validateCode = async (requestId: string, code: string): Promise<void> => {
  const result: Response = await retry(
    async () => await fetch(`${MESSAGE_HOST}/${SPEC_ID}/process/validate/${requestId}-${code}`),
    3,
    500,
  );
  if (result.status === 404) {
    throw ERROR.ID_CODE_WRONG;
  }
};

const getNewTokens = async (
  patient: Patient,
  patientToken: string,
  groupId: string,
  tokenId: string = getAppId(),
  validity: number = SIX_MONTHS,
): Promise<TokenByGroup[]> => {
  if (!groupId) throw new Error('GroupId missing');
  if (!patient.email) throw new Error('No email found');

  const NAMESPACE = 'be.medispring.online.calendar';
  const putCurrentGroupAtEnd = (a: UserGroup) => (a.groupId === groupId ? 1 : -1);
  const { userApi }: IcureApiService = await getApi(new Credentials({
    username: patient.email!,
    password: patientToken,
  }));
  const userGroups: UserGroup[] = await userApi!.getMatchingUsers();

  return Promise.all(
    userGroups.sort(putCurrentGroupAtEnd).map(async ({ groupId, userId }) => {
      const { userApi }: IcureApiService = await getApi(new Credentials({
        username: `${groupId}/${userId}`,
        password: patientToken,
      }));
      return {
        groupId: groupId!,
        token: await userApi!.getToken(userId!, `${NAMESPACE}-${tokenId}`, validity),
      };
    }),
  );
};

export const configureNewPatient = async (
  patient: Patient,
  patientToken: string,
  healthcareParty: HealthcareParty,
  groupId: string,
) => {
  const patientUser: User = (await getUser(patient, groupId, patientToken))!;
  const username: string = `${groupId}/${patientUser.id!}`;
  if (!patientUser.patientId && patientUser.healthcarePartyId) {
    throw new Error(ERROR.IS_MEDISPRING_USER);
  }
  const api: IcureApiService = await getApi(new Credentials({ username, password: patientToken }));
  const icurePatient: IcurePatient = await retry(
    () => api.patientApi!.getPatientWithUser(patientUser, patientUser.patientId!),
    3,
    500,
  );
    icurePatientPromise = addHcpDelegationsAndFlagPatient(
      { password: patientToken, username },
      icurePatient,
      patientUser,
      healthcareParty,
    );
};

const addHcpDelegationsAndFlagPatient = async (
  credentials: LoginCredentials,
  patient: IcurePatient,
  user: User,
  delegate: HealthcareParty,
): Promise<IcurePatient | null | undefined> => {
  const icureApi: IcureApiService = await getApi(new Credentials(credentials));
  const cryptoHelper = new CryptoHelper(icureApi);

  try {

    // Get patient's secret id
    const patientSecretIds: string[] = await cryptoHelper.decryptSecretIdsOf(patient);

    // Get delegate's parent, if any
    const parentHcp: HealthcareParty = await gethealthcarepartyAncestor(icureApi.hcpartyApi!, delegate);

    // Create map of delegates to share patient with
    const delegatesToSharePatientWith = { [delegate.id!]: { shareSecretIds: patientSecretIds } };

    // Add parent to delegates, if any
    if (parentHcp?.id) delegatesToSharePatientWith[parentHcp?.id] = { shareSecretIds: patientSecretIds };

    // Do share patient with delegate (its doctor) and doctor's parent (if any)
    patient = await icureApi.patientApi!.shareWithMany(patient, delegatesToSharePatientWith);

    return await icureApi.patientApi!.modifyPatientWithUser(user, {
      ...patient,
      tags: [...(patient.tags || (patient.tags = [])), PATIENT_APP_TAG],
    });
  } catch {
    console.warn(
      "Configuration of new patient failed: no delegations added. This shouldn't prevent the appointment but the hcp will not be able to access this patient in Medispring.",
    );
  }
  return null;
};

export const saveCalendarItem = async ({
  patient,
  patientToken,
  appointmentType,
  healthcareParty,
  timeslot,
  groupId,
  user,
  appointmentNote,
  placeId,
}: FlowState): Promise<CalendarItem> => {
  if (!appointmentType) {
    throw new Error('No appointment type provided');
  }
  const patientUser: User = (await getUser(patient!, groupId!, patientToken))!;
  const username: string = `${groupId}/${patientUser.id!}`;
  const api: IcureApiService = await getApi(new Credentials({
    username,
    password: patientToken,
  }));

  const { id: agendaId } = await retry(() => api.agendaApi!.getAgendasForUser(user?.id!), 3, 500);
  let calendarItem: CalendarItem = buildCalendarItem(
    healthcareParty!,
    agendaId!,
    patient!,
    appointmentType!,
    timeslot!,
    appointmentNote,
    placeId,
  );

  const highestHcpId: string | undefined = (await gethealthcarepartyAncestor(api.hcpartyApi, healthcareParty))!.id!;
  const delegatesMap = {
    [highestHcpId ? highestHcpId : healthcareParty!.id!]: SecureDelegation.AccessLevelEnum.WRITE,
  };

  try {
    const body = await retry(
      async () =>
        await api.calendarItemApi!.newInstance(patientUser, calendarItem, { additionalDelegates: delegatesMap }),
      3,
      500,
    );
    const calendarItemWithHCP: CalendarItem = await retry(
      async () => api.calendarItemApi!.createCalendarItemWithHcParty(patientUser, body),
      3,
      500,
    );

    // Get icure-access-control-keys (custom header) should appointment be cancelled on another device
    const icureAccessControlKey: string = (await api.cryptoApi?.accessControlKeysHeaders?.getAccessControlKeysHeaders(EntityWithDelegationTypeName.CalendarItem))?.[0]?.data || '';

    await sendConfirmationEmail(
      {
        patient,
        patientToken,
        healthcareParty,
        timeslot,
        groupId,
      },
      calendarItemWithHCP,
      icureAccessControlKey ? btoa(icureAccessControlKey) : undefined
    ).catch(e => {
      Sentry.captureException(e);
      throw e;
    });

    return calendarItemWithHCP;
  } catch (e) {
    const icurePatient =
      (await icurePatientPromise) || (await api.patientApi!.getPatientWithUser(patientUser, patientUser?.patientId!));
    if (icurePatient.deletionDate) {
      throw new Error(ERROR.DELETED_PATIENT);
    }
    throw e;
  }
};

export const modifyCalendarItem = async (
  calendarItem: CalendarItem,
  patient: Patient,
  patientToken: string,
  groupId: string,
  accessControlKey?: string |  undefined
) => {
  const customHeaders: MapStringOf<string> = accessControlKey ? { 'Icure-Access-Control-Keys': accessControlKey } : {};
  const api: IcureApiService = await getApi(new Credentials({
    username: `${groupId}/${patient.userId!}`,
    password: patientToken,
  }), customHeaders);

  const user: User = (await getUser(patient, groupId, patientToken))!;
  const isCalendarItemDecrypted: boolean = Boolean(calendarItem?.details || calendarItem?.title || calendarItem?.patientId);

  const modifiedCalendarItem: CalendarItem = !isCalendarItemDecrypted ? undefined : await retry(() => api.calendarItemApi!.modifyCalendarItemWithHcParty(user, calendarItem), 3, 500).catch(e => undefined);
  return modifiedCalendarItem ?? await retry(() => api.nonDecryptedIcureApis!.calendarItemApi!.modifyCalendarItem(calendarItem), 3, 500).catch(e => calendarItem);
};

export const modifyCalendarItemRaw = async (
  calendarItem: CalendarItem,
  patient: Patient,
  patientToken: string,
  groupId: string,
) => {
  const { nonDecryptedIcureApis } = await getApi(new Credentials({
    username: `${groupId}/${patient.userId!}`,
    password: patientToken,
  }));
  return await retry(() => nonDecryptedIcureApis!.calendarItemApi.modifyCalendarItem(calendarItem), 3, 500);
};

export const deleteCalendarItem = async (
  calendarItemId: string,
  patient: Patient,
  patientToken: string,
  groupId: string,
) => {
  const { calendarItemApi } = await getApi(new Credentials({
    username: `${groupId}/${patient.userId!}`,
    password: patientToken,
  }));
  await retry(() => calendarItemApi!.deleteCalendarItems({ ids: [calendarItemId] }), 3, 500);
};

export const getCalendarItem = async (
  calendarItemId: string,
  patient: Patient,
  patientToken: string,
  groupId: string,
  accessControlKey?: string |  undefined
): Promise<CalendarItem> => {
  const customHeaders: MapStringOf<string> = accessControlKey ? { 'Icure-Access-Control-Keys': accessControlKey } : {};
  const api: IcureApiService = await getApi(new Credentials({
    username: `${groupId}/${patient.userId!}`,
    password: patientToken,
  }), customHeaders);

  const user: User = (await getUser(patient, groupId, patientToken))!;
  const decryptedCalendarItem: CalendarItem | undefined = await retry(() => api.calendarItemApi!.getCalendarItemWithUser(user, calendarItemId), 2, 200).catch(e => undefined);

  // Either we got the decrypted calendarItem (with keyPair being available in localStorage), or we're going for the non-decrypted one
  return decryptedCalendarItem ?? await retry(() => api.nonDecryptedIcureApis!.calendarItemApi!.getCalendarItem(calendarItemId), 2, 200).catch(e => ({}));
};

export const getUser = async (
  patient: Patient,
  groupId?: string,
  tempToken?: string,
): Promise<User | null | undefined> => {
  const { email, userId } = patient;
  const login: string | undefined = userId || email;
  const password: string | undefined = tempToken;
  if (patientUserPromise) {
    return patientUserPromise;
  }
  if (!login) {
    throw new Error('No login found.');
  }
  if (!password) {
    throw new Error('No password found.');
  }
  const userApi = await getUserApi(`${userId && groupId ? `${groupId}/` : ''}${login}`, password);
  return await (patientUserPromise = retry(() => userApi!.getCurrentUser(), 3, 500));
};

const getFullHealthcareParty = async (hcpartyApi: any, healthcarePartyId: string): Promise<HealthcareParty> => {
  return retry(() => hcpartyApi.getHealthcareParty(healthcarePartyId), 3, 500);
};

export const clearCache = () => {
  patientUserPromise = undefined;
  icurePatientPromise = undefined;
};
