export enum ClassNames {
  submitted = 'submitted',
  validated = 'validated',
  flowContent = 'flow-content',
}

export enum ERROR {
  ID_CODE_INVALID = 'ID_CODE_INVALID',
  ID_CODE_WRONG = 'ID_CODE_WRONG',
  ID_SERVER = 'ID_SERVER',
  PATIENT_CONFIGURATION = 'PATIENT_CONFIGURATION',
  SAVE_APPOINTMENT = 'SAVE_APPOINTMENT',
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  RECAPTCHA = 'RECAPTCHA',
  IS_MEDISPRING_USER = 'IS_MEDISPRING_USER',
  DELETED_PATIENT = 'DELETED_PATIENT',
  EMAIL_NOT_SENT = 'EMAIL_NOT_SENT',
  REMINDER_EMAIL_NOT_SENT = 'REMINDER_EMAIL_NOT_SENT',
}

export interface TokenByGroup {
  groupId: string;
  token: string;
}

export interface Patient {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  visitedHealthcarePartyIds: string[];
  userId?: string;
}

export enum ScreenName {
  PLACE_CHOICE = 'PLACE_CHOICE',
  NEW_PATIENT_QUESTION = 'NEW_PATIENT_QUESTION',
  NEW_PATIENT_REJECTION = 'NEW_PATIENT_REJECTION',
  CALENDAR_ITEM_TYPE_CHOICE = 'CALENDAR_ITEM_TYPE_CHOICE',
  TIMESLOT_CHOICE = 'TIMESLOT_CHOICE',
  IDENTIFICATION = 'IDENTIFICATION',
  VALIDATION = 'VALIDATION',
  SAVE_APPOINTMENT = 'SAVE_APPOINTMENT',
  END = 'END',
}

export interface MailData {
  ['g-recaptcha-response']: string;
  from: string;
  hcp: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobilePhone: string;
  group: string;
  hcpId: string;
}

export enum InputName {
  LASTNAME = 'LASTNAME',
  FIRSTNAME = 'FIRSTNAME',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  REMEMBER_ME = 'REMEMBER_ME',
  REASON = 'REASON',
}