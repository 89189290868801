import i18next from 'i18next';

interface Props {
  message?: string;
}

export default function Loading({ message = i18next.t('UI.LOADING') }: Props) {
  return (
    <div className="loading">
      <img alt="Medispring logo" src={require('../../assets/img/logo.svg').default} />
      <div className="small-text light message">{message}</div>
    </div>
  );
}
