import {fetch as fetchPolyfill} from 'whatwg-fetch';

import {
  AuthenticationProvider,
  BasicAuthenticationProvider,
  EncryptedFieldsConfig,
  IccAccesslogApi,
  IccAccesslogXApi,
  IccAgendaApi,
  IccAnonymousAccessApi,
  IccApplicationsettingsApi,
  IccArticleApi,
  IccAuthApi,
  IccBeefactApi,
  IccBekmehrXApi,
  IccBeresultexportApi,
  IccBeresultimportApi,
  IccBesamv2Api,
  IccCalendarItemApi,
  IccCalendarItemTypeApi,
  IccCalendarItemXApi,
  IccClassificationTemplateApi,
  IccClassificationXApi,
  IccCodeXApi,
  IccContactApi,
  IccContactXApi,
  IccCryptoXApi,
  IccDataOwnerXApi,
  IccDeviceApi,
  IccDoctemplateXApi,
  IccDocumentXApi,
  IccEntityrefApi,
  IccEntitytemplateApi,
  IccFormXApi,
  IccFrontendmigrationApi,
  IccGroupApi,
  IccHcpartyXApi,
  IccHelementXApi,
  IccIcureApi,
  IccIcureMaintenanceXApi,
  IccInsuranceApi,
  IccInvoiceXApi,
  IccKeywordApi,
  IccMaintenanceTaskXApi,
  IccMedexApi,
  IccMedicallocationApi,
  IccMessageXApi,
  IccPatientApi,
  IccPatientXApi,
  IccPermissionApi,
  IccPlaceApi,
  IccPubsubApi,
  IccReceiptXApi,
  IccRecoveryXApi,
  IccReplicationApi,
  IccTarificationApi,
  IccTimeTableXApi,
  IccTmpApi,
  IccUserXApi,
  IcureApi,
  IcureApiOptions,
  KeyStorageFacade,
  KeyStorageImpl,
  LocalStorageImpl,
  StorageFacade,
  UserGroup,
} from '@icure/api';

import {RsaHelper} from '../helpers/rsa.helper';
import {IcureTokensHelper} from '../helpers/icure-tokens.helper';
import {IcureApiCryptoStrategies} from '../helpers/icure-api-crypto-strategies';
import {AuthenticationDetails} from "@icure/api/icc-x-api";

export interface RetryOptions {
  retryCount?: number;
  sleepTime?: number;
  exponentialFactor?: number;
}

export interface NonDecryptedIcureApis {
  accessLogApi: IccAccesslogApi;
  calendarItemApi: IccCalendarItemApi;
  contactApi: IccContactApi;
  patientApi: IccPatientApi;
}

export class IcureApiService {
  private host: string | undefined;
  private headers: any;
  private rsaHelper: RsaHelper;
  private authenticationOptions: AuthenticationProvider | AuthenticationDetails | undefined;

  public accessLogApi: IccAccesslogXApi | undefined;
  public agendaApi: IccAgendaApi | undefined;
  public anonymousAccessApi: IccAnonymousAccessApi | undefined;
  public applicationSettingsApi: IccApplicationsettingsApi | undefined;
  public articleApi: IccArticleApi | undefined;
  public authApi: IccAuthApi | undefined;
  public beefactApi: IccBeefactApi | undefined;
  public bekmehrApi: IccBekmehrXApi | undefined;
  public beresultexportApi: IccBeresultexportApi | undefined;
  public beresultimportApi: IccBeresultimportApi | undefined;
  public besamv2Api: IccBesamv2Api | undefined;
  public calendarItemApi: IccCalendarItemXApi | undefined;
  public calendarItemTypeApi: IccCalendarItemTypeApi | undefined;
  public classificationApi: IccClassificationXApi | undefined;
  public classificationTemplateApi: IccClassificationTemplateApi | undefined;
  public codeApi: IccCodeXApi | undefined;
  public contactApi: IccContactXApi | undefined;
  public cryptoApi: IccCryptoXApi | undefined;
  public dataOwnerApi: IccDataOwnerXApi | undefined;
  public deviceApi: IccDeviceApi | undefined;
  public doctemplateApi: IccDoctemplateXApi | undefined;
  public documentApi: IccDocumentXApi | undefined;
  public entityrefApi: IccEntityrefApi | undefined;
  public entitytemplateApi: IccEntitytemplateApi | undefined;
  public formApi: IccFormXApi | undefined;
  public frontendmigrationApi: IccFrontendmigrationApi | undefined;
  public groupApi: IccGroupApi | undefined;
  public hcpartyApi: IccHcpartyXApi | undefined;
  public helementApi: IccHelementXApi | undefined;
  public icureApi: IccIcureApi | undefined;
  public icureMaintenanceTaskApi: IccIcureMaintenanceXApi | undefined;
  public insuranceApi: IccInsuranceApi | undefined;
  public invoiceApi: IccInvoiceXApi | undefined;
  public keywordApi: IccKeywordApi | undefined;
  public maintenanceTaskApi: IccMaintenanceTaskXApi | undefined;
  public medexApi: IccMedexApi | undefined;
  public medicalLocationApi: IccMedicallocationApi | undefined;
  public messageXApi: IccMessageXApi | undefined;
  public patientApi: IccPatientXApi | undefined;
  public permissionApi: IccPermissionApi | undefined;
  public placeApi: IccPlaceApi | undefined;
  public pubsubApi: IccPubsubApi | undefined;
  public receiptApi: IccReceiptXApi | undefined;
  public recoveryApi: IccRecoveryXApi | undefined;
  public replicationApi: IccReplicationApi | undefined;
  public tarificationApi: IccTarificationApi | undefined;
  public timeTableApi: IccTimeTableXApi | undefined;
  public tmpApi: IccTmpApi | undefined;
  public userApi: IccUserXApi | undefined;

  public nonDecryptedIcureApis: NonDecryptedIcureApis | undefined;

  constructor() {
    this.rsaHelper = new RsaHelper();
  }

  public async initialise(
    host: string,
    headers: any,
    authenticationOptions: AuthenticationProvider | AuthenticationDetails,
  ): Promise<void> {
    this.host = host;
    this.headers = headers;
    this.authenticationOptions = authenticationOptions;

    if (!(await this.areCredentialsProvided())) return;

    const storage: StorageFacade<string> = new LocalStorageImpl();
    const keyStorage: KeyStorageFacade = new KeyStorageImpl(storage);
    const encryptedFieldsConfig: EncryptedFieldsConfig = {
      patient: ['note', 'properties[].typedValue'],
      calendarItem: ['details', 'title', 'patientId', 'phoneNumber'],
    };
    const crypto: Crypto = window?.crypto ?? ({} as Crypto);
    const icureApiCryptoStrategies: IcureApiCryptoStrategies = new IcureApiCryptoStrategies(
      undefined,
      undefined,
      this.rsaHelper,
    );

    const icureApiOptions: IcureApiOptions = {
      storage: storage,
      keyStorage: keyStorage,
      headers: this.headers,
      encryptedFieldsConfig: encryptedFieldsConfig,
      groupSelector: this.getGroupSelectorCallback,
    };

    const iccApis: IcureApi = await IcureApi.initialise(
      this.host,
      authenticationOptions,
      icureApiCryptoStrategies,
      crypto,
      fetchPolyfill,
      icureApiOptions,
    ).catch(error => {
      this.handleIcureApiInitialisationFailed(error);
      throw error;
    });

    this.accessLogApi = iccApis.accessLogApi;
    this.agendaApi = iccApis.agendaApi;
    this.anonymousAccessApi = iccApis.anonymousAccessApi;
    this.applicationSettingsApi = iccApis.applicationSettingsApi;
    this.articleApi = iccApis.articleApi;
    this.authApi = iccApis.authApi;
    this.beefactApi = iccApis.beefactApi;
    this.bekmehrApi = iccApis.bekmehrApi;
    this.beresultexportApi = iccApis.beresultexportApi;
    this.beresultimportApi = iccApis.beresultimportApi;
    this.besamv2Api = iccApis.besamv2Api;
    this.calendarItemApi = iccApis.calendarItemApi;
    this.calendarItemTypeApi = iccApis.calendarItemTypeApi;
    this.classificationApi = iccApis.classificationApi;
    this.classificationTemplateApi = iccApis.classificationTemplateApi;
    this.codeApi = iccApis.codeApi;
    this.contactApi = iccApis.contactApi;
    this.cryptoApi = iccApis.cryptoApi;
    this.dataOwnerApi = iccApis.dataOwnerApi;
    this.deviceApi = iccApis.deviceApi;
    this.doctemplateApi = iccApis.doctemplateApi;
    this.documentApi = iccApis.documentApi;
    this.entityrefApi = iccApis.entityReferenceApi;
    this.entitytemplateApi = iccApis.entitytemplateApi;
    this.formApi = iccApis.formApi;
    this.frontendmigrationApi = iccApis.frontendmigrationApi;
    this.groupApi = iccApis.groupApi;
    this.hcpartyApi = iccApis.healthcarePartyApi;
    this.helementApi = iccApis.healthcareElementApi;
    this.icureApi = iccApis.icureApi;
    this.icureMaintenanceTaskApi = iccApis.icureMaintenanceTaskApi;
    this.insuranceApi = iccApis.insuranceApi;
    this.invoiceApi = iccApis.invoiceApi;
    this.keywordApi = iccApis.keywordApi;
    this.maintenanceTaskApi = iccApis.maintenanceTaskApi;
    this.medexApi = iccApis.medexApi;
    this.medicalLocationApi = iccApis.medicalLocationApi;
    this.messageXApi = iccApis.messageApi;
    this.patientApi = iccApis.patientApi;
    this.permissionApi = iccApis.permissionApi;
    this.placeApi = iccApis.placeApi;
    this.pubsubApi = iccApis.pubsubApi;
    this.receiptApi = iccApis.receiptApi;
    this.recoveryApi = iccApis.recoveryApi;
    this.replicationApi = iccApis.replicationApi;
    this.tarificationApi = iccApis.tarificationApi;
    this.timeTableApi = iccApis.timetableApi;
    this.tmpApi = iccApis.tmpApi;
    this.userApi = iccApis.userApi;

    // @ts-ignore
    window['iccApi'] = this;

    // For non-decrypted version
    this.getNonDecryptedIcureApi(iccApis);
  }

  /**
   * **Verifies if credentials are provided by SmartAuthenticationDetails.**
   *
   * - Could either be a **regular login** with username & password.
   * - Or an **existing Icure JWT** recovered by getIcureTokensFromCredentialsOrFromSessionStorage.
   *
   * @see getIcureTokensFromCredentialsOrFromSessionStorage
   * @private
   */
  private async areCredentialsProvided(): Promise<boolean> {
    // @ts-ignore
    return Boolean(this.authenticationOptions?.['username'] && this.authenticationOptions?.['password'])
  }

  /**
   * Initializes **non-decrypted Icure Apis** under property **nonDecryptedIcureApis**.
   *
   * Non-decrypted Icure Apis are:
   *
   * - AccesslogApi
   * - CalendarItemApi
   * - ContactApi
   * - PatientApi
   *
   * Since decryption takes substantial time, provide with non-decrypted apis for performance purposes.
   *
   * Patch some methods to prevent change detection on promise callbacks.
   *
   * @param iccApis {IcureApi} Initialized Icure Apis (which includes decryption).
   * @private
   */
  private getNonDecryptedIcureApi(iccApis: IcureApi): void {
    if (!iccApis) return;

    // @ts-ignore
    const host: string = iccApis['host'];
    // @ts-ignore
    const headers: string = iccApis['params']?.headers;
    const authenticationOptions: AuthenticationProvider = iccApis.authApi?.authenticationProvider;

    this.nonDecryptedIcureApis = {
      accessLogApi: new IccAccesslogApi(host, headers, authenticationOptions, fetchPolyfill),
      calendarItemApi: new IccCalendarItemApi(host, headers, authenticationOptions, fetchPolyfill),
      contactApi: new IccContactApi(host, headers, authenticationOptions, fetchPolyfill),
      patientApi: new IccPatientApi(host, headers, authenticationOptions, fetchPolyfill),
    };

    // @ts-ignore
    window['iccApiND'] = this.nonDecryptedIcureApis;
  }

  /**
   * Handles **IcureApi initialisation failure**.
   *
   * - Logs error.
   * - Creates new IccAuthApi instance with **basic authentication** (username & password).
   * - Logs out using basic auth.
   * - Gets iCureRefreshToken (from session storage) and invalidates it using basic auth.
   * - Deletes Icure username from session storage
   * - Deletes Icure groupId from session storage
   * - Deletes iCureTokens from session storage
   * - Deletes iCureTokens (cloud) from session storage
   * - Redirects to login page.
   *
   * @see deleteAll
   * @see deleteUsername
   * @see deleteGroupId
   * @see deleteTokens
   * @see deleteTokensCloud
   *
   * @param error The error to log.
   * @private
   */
  private handleIcureApiInitialisationFailed(error: any): void {
    console.error('Failed to initialize IcureApi', error);

    const iccAuthApiWithBasicAuth: IccAuthApi = new IccAuthApi(
      this.host ?? '',
      this.headers,
      new BasicAuthenticationProvider(
        // @ts-ignore
        this.authenticationOptions?.['username'] ?? '',
        // @ts-ignore
        this.authenticationOptions?.['password'] ?? '',
      ),
      fetchPolyfill,
    );

    iccAuthApiWithBasicAuth.logout().catch(e => null);
    iccAuthApiWithBasicAuth.invalidateRefreshJWT(IcureTokensHelper.getRefreshToken() ?? '').catch(e => null);

    IcureTokensHelper.deleteAll();
  }

  private async getGroupSelectorCallback(availableGroupsInfo: UserGroup[]): Promise<string> {
    const groupInfo: UserGroup = (availableGroupsInfo || []).filter(it => it.groupId && it.patientId && it.userId)?.[0];
    return groupInfo?.groupId ?? '';
  }
}
