import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import Logo from './assets/img/logo.png';
import { Languages } from './services/translations';

export default function Header() {
  const { t, i18n } = useTranslation();

  return (
    <header className="wrap">
      <div className="company">
        <img alt="Medispring logo" src={Logo} className="logo" />
        <div className="name">
          <span>{t('UI.HEADER.APP_NAME')}</span>
          <span>{t('UI.HEADER.POWERED_BY')}</span>
        </div>
      </div>

      <select
        className="languageSelector"
        name="language"
        onChange={e => {
          i18n.changeLanguage(e.target.value);
          dayjs.locale(e.target.value);
        }}
      >
        {Object.entries(Languages).map(([name, code]) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
    </header>
  );
}
