import { HistorySteps } from './components/HistorySteps';
import { FlowState } from '../AppointmentFlow';
import { ClassNames, InputName, Patient, ScreenName } from './service/types';
import { useTranslation } from 'react-i18next';
import { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { attemptToNormalizePhoneNumber, getInvalidFields } from './service/AppointmentFlowServices';
import { deletePatient, savePatient } from '../service/localStorage';
import {
  getAgendaConfiguration,
  selectAppointmentTypesByPlaceId,
  trimPatientNamesFromWhiteSpaceAndSpecialCharacters,
} from '../../services/utils';
import { clearCache } from '../../services/apiService';
//@ts-ignore
import info from '../../assets/img/info.svg';

interface Props {
  flowState: FlowState;
  // eslint-disable-next-line
  gotoScreen: (screenName: ScreenName, {}: FlowState) => void;
  onSubmit: (property: {}) => void;
}

export type Validation = {
  [key in InputName]?: boolean;
};

const Identification = ({
  onSubmit,
  gotoScreen,
  flowState,
  flowState: {
    appointmentType,
    appointmentNote,
    appointmentTypes,
    placeId,
    patient: pat = { visitedHealthcarePartyIds: [], tokensByGroup: [] },
    timeslot,
    groupId,
    rememberMe: remember,
  },
}: Props) => {
  const { t } = useTranslation();
  const [patient, setPatient] = useState<Patient>(pat);
  const [validation, setValidation] = useState<Validation>({});
  const [errors, setErrors] = useState<InputName[]>([]);
  const [submitted, setSubmit] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(!!remember);
  const [note, setNote] = useState<string>(appointmentNote || '');

  const validate = ({ firstName, lastName, email, phoneNumber }: Patient) => {
    setValidation({
      [InputName.FIRSTNAME]: /[a-zA-Z]/.test(firstName!),
      [InputName.LASTNAME]: /[a-zA-Z]/.test(lastName!),
      [InputName.EMAIL]: /^\S+@\S+\.\S+$/.test(email || ''),
      [InputName.PHONE]: !!phoneNumber?.match(/^[^a-zA-Z]{8,}$/gs),
    });
  };

  useEffect(() => {
    validate(patient);
  }, [patient]);

  const handleChange = ({ target: { value, checked, attributes } }: BaseSyntheticEvent) => {
    switch (attributes.getNamedItem('id').nodeValue) {
      case InputName.FIRSTNAME:
        setPatient({ ...patient, firstName: value, userId: undefined });
        break;
      case InputName.LASTNAME:
        setPatient({ ...patient, lastName: value, userId: undefined });
        break;
      case InputName.EMAIL:
        setPatient({ ...patient, email: value, userId: undefined });
        break;
      case InputName.PHONE: {
        setPatient({ ...patient, phoneNumber: attemptToNormalizePhoneNumber(value), userId: undefined });
        break;
      }
      case InputName.REMEMBER_ME: {
        setRememberMe(checked);
        break;
      }
      case InputName.REASON: {
        setNote(value);
      }
    }
    clearCache();
  };

  const storeAndSubmit = () => {
    const patientWithTrimmedNames: Patient = trimPatientNamesFromWhiteSpaceAndSpecialCharacters(patient);
    const errors: InputName[] = getInvalidFields(validation);
    setErrors(errors);
    setSubmit(true);
    if (!errors.length) {
      if (rememberMe) {
        savePatient(patientWithTrimmedNames);
      } else {
        deletePatient();
      }
      onSubmit({
        patient: patientWithTrimmedNames,
        appointmentNote: note,
        rememberMe,
      });
    }
  };

  const autoFocusCallback = useCallback(element => (element ? element.focus() : null), []);

  const config = getAgendaConfiguration(flowState.healthcareParty!);

  return (
    <div className="Identification">
      <ol className="ordered-list">
        <HistorySteps
          untilScreen={ScreenName.TIMESLOT_CHOICE}
          flowState={flowState}
          gotoScreen={gotoScreen}
          selections={{
            appointmentType: appointmentType || selectAppointmentTypesByPlaceId(appointmentTypes!, placeId!)[0],
            timeslot,
          }}
        />
        <li className="list-item identification-form">
          <h3 className="screen-title">{t('FLOW.IDENTIFICATION.TITLE')}</h3>
          <div className={ClassNames.flowContent}>
            <label htmlFor={InputName.FIRSTNAME}>{t('FLOW.IDENTIFICATION.FIRSTNAME')} :</label>
            <input
              autoComplete="given-name"
              autoFocus
              ref={autoFocusCallback}
              className={`form-input textfield ${validation.FIRSTNAME ? ClassNames.validated : ''} ${submitted ? ClassNames.submitted : ''}`}
              id={InputName.FIRSTNAME}
              value={patient.firstName}
              onInput={handleChange}
            />

            <label htmlFor={InputName.LASTNAME}>{t('FLOW.IDENTIFICATION.LASTNAME')} :</label>
            <input
              autoComplete="family-name"
              className={`form-input textfield ${validation.LASTNAME ? ClassNames.validated : ''} ${submitted ? ClassNames.submitted : ''}`}
              id={InputName.LASTNAME}
              value={patient.lastName}
              onInput={handleChange}
            />

            <label htmlFor={InputName.EMAIL}>{t('FLOW.IDENTIFICATION.EMAIL')} :</label>
            <input
              autoComplete="email"
              value={patient.email}
              className={`form-input textfield ${validation.EMAIL ? ClassNames.validated : ''} ${submitted ? ClassNames.submitted : ''}`}
              id={InputName.EMAIL}
              onInput={handleChange}
            />

            <label htmlFor={InputName.PHONE}>{t('FLOW.IDENTIFICATION.PHONE')} :</label>
            <input
              autoComplete="tel"
              value={patient.phoneNumber}
              id={InputName.PHONE}
              className={`form-input textfield ${validation.PHONE ? ClassNames.validated : ''} ${submitted ? ClassNames.submitted : ''}`}
              onInput={handleChange}
            />

            {config.allowPatientToIncludeNoteUponAppointmentCreation && (
              <>
                <label htmlFor={InputName.REASON}>{t('FLOW.IDENTIFICATION.REASON')} :</label>
                <textarea rows={3} id={InputName.REASON} className="form-input textfield" onInput={handleChange} />
              </>
            )}

            <div>
              <input
                type="checkbox"
                checked={rememberMe}
                id={InputName.REMEMBER_ME}
                className="form-input checkbox"
                onChange={handleChange}
              />
              <label className="checkbox-label" htmlFor={InputName.REMEMBER_ME}>
                {t('FLOW.IDENTIFICATION.REMEMBER_ME')}
                <span className="more-info">
                  <img alt="More info" src={info} />
                </span>
                <div className="tooltip small-text">{t('TOOLTIP.REMEMBER_ME')}</div>
              </label>

              <p
                className="small-text"
                dangerouslySetInnerHTML={{ __html: t('FLOW.IDENTIFICATION.POLICY', { action: t('BUTTONS.NEXT') }) }}
              />
              <p className="warning">{t('FLOW.IDENTIFICATION.WARNING')}</p>
            </div>
            <button id="confirmation-button" className="button secondary" onClick={() => storeAndSubmit()}>
              {t('BUTTONS.NEXT')}
            </button>
          </div>
        </li>
      </ol>

      {errors.length ? (
        <div className="error-message">
          {t('FLOW.IDENTIFICATION.ERROR', {
            missingFields: errors.map((field: InputName) => t(`FLOW.IDENTIFICATION.${field}`).toLowerCase()).join(', '),
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Identification;
