import {
  DataOwner,
  hex2ua,
  KeyPair,
  keyPairFromPrivateKeyJwk,
  pkcs8ToJwk,
  RSAUtilsImpl,
  ShaVersion,
  ua2hex,
} from '@icure/api';
import {KeyPairByDataOwnerId, MapStringOf} from '../model/rsa.model';

export class RsaHelper {
  private readonly rsaUtils: RSAUtilsImpl = new RSAUtilsImpl(crypto);

  /**
   * Gets the public **keys** of a data owner in hexadecimal, same as {@link IccDataOwnerXApi.getHexPublicKeysOf}.
   *
   * Instance of IccDataOwnerXApi might **not** be available yet though.
   * @param dataOwner a data owner.
   * @return A set of public **keys** for the data owner (hexadecimal representation).
   */
  public getDataOwnerHexadecimalPublicKeys(dataOwner: DataOwner): Set<string> | null {
    return !dataOwner
      ? null
      : new Set(
          [
            dataOwner.publicKey || '',
            ...Object.keys(dataOwner.aesExchangeKeys || {}),
            ...(dataOwner?.publicKeysForOaepWithSha256 || []),
          ].filter(Boolean) as string[],
        );
  }

  /**
   * Transforms an **hexadecimal** represented private key into a **keyPair<JsonWebKey>**.
   * @param hexadecimalPrivateKey The hexadecimal string representation of the private key.
   * @param shaVersion The private key's SHA algorithm version (either sha-1 or sha-256).
   * @return The **keyPair<JsonWebKey>** corresponding to the given hexadecimal private key.
   */
  public hexadecimalPrivateKey2keyPairJsonWebKey(hexadecimalPrivateKey: string, shaVersion: ShaVersion): KeyPair<JsonWebKey> | null {
    return !hexadecimalPrivateKey ? null : keyPairFromPrivateKeyJwk(pkcs8ToJwk(hex2ua(hexadecimalPrivateKey)), shaVersion);
  }

  /**
   * Transforms an **hexadecimal** represented private key into a **keyPair<CryptoKey>**.
   * @param hexadecimalPrivateKey The hexadecimal string representation of the private key.
   * @param shaVersion The private key's SHA algorithm version (either sha-1 or sha-256).
   * @return The **keyPair<CryptoKey>** corresponding to the given hexadecimal private key.
   * @see hexadecimalPrivateKey2keyPairJsonWebKey
   * @see keyPairJsonWebKey2keyPairCryptoKey
   */
  public async hexadecimalPrivateKey2keyPairCryptoKey(hexadecimalPrivateKey: string, shaVersion: ShaVersion): Promise<KeyPair<CryptoKey> | null> {
    const keyPairJsonWebKey: KeyPair<JsonWebKey> | null = this.hexadecimalPrivateKey2keyPairJsonWebKey(hexadecimalPrivateKey, shaVersion);
    return !keyPairJsonWebKey?.privateKey || !keyPairJsonWebKey?.publicKey ? null : await this.keyPairJsonWebKey2keyPairCryptoKey(keyPairJsonWebKey, shaVersion);
  }

  /**
   * Transforms a **keyPair<CryptoKey>** into **keyPair<ArrayBuffer>** (RSA exported keys).
   * @param keyPairCryptoKey The keyPair<CryptoKey> to transform.
   * @return The **KeyPair<ArrayBuffer>** representation of **keyPair<CryptoKey>**.
   */
  public async keyPairCryptoKey2KeyPairArrayBuffer(keyPairCryptoKey: KeyPair<CryptoKey>): Promise<KeyPair<ArrayBuffer> | null> {
    return !keyPairCryptoKey?.publicKey || !keyPairCryptoKey?.privateKey ? null : await this.rsaUtils.exportKeys(keyPairCryptoKey, 'pkcs8', 'spki');
  }

  /**
   * Transforms a **keyPair<ArrayBuffer>** into **keyPair<string>** (hexadecimal representation).
   * @param keyPairArrayBuffer The keyPair<ArrayBuffer> to transform.
   * @return The **hexadecimal** representation as **keyPair<string>**.
   */
  public keyPairArrayBuffer2KeyPairHexadecimal(keyPairArrayBuffer: KeyPair<ArrayBuffer> | null, ): KeyPair<string> | null {
    return !keyPairArrayBuffer?.publicKey || !keyPairArrayBuffer?.privateKey
      ? null
      : ({
          publicKey: ua2hex(keyPairArrayBuffer?.publicKey) || null,
          privateKey: ua2hex(keyPairArrayBuffer?.privateKey) || null,
        } as KeyPair<string>);
  }

  /**
   * Transforms a **keyPair<CryptoKey>** into **keyPair<string>** (hexadecimal representation).
   * @param keyPairCryptoKey The keyPair<CryptoKey> to transform.
   * @return The **hexadecimal** representation as **keyPair<string>**.
   * @see keyPairCryptoKey2KeyPairArrayBuffer
   * @see keyPairArrayBuffer2KeyPairHexadecimal
   */
  public async keyPairCryptoKey2KeyPairHexadecimal(keyPairCryptoKey: KeyPair<CryptoKey>): Promise<KeyPair<string> | null> {
    const keyPairArrayBuffer: KeyPair<ArrayBuffer> | null = await this.keyPairCryptoKey2KeyPairArrayBuffer(keyPairCryptoKey);
    return this.keyPairArrayBuffer2KeyPairHexadecimal(keyPairArrayBuffer);
  }

  /**
   * Transforms a **keyPair<CryptoKey>** into an **hexadecimal** representation of the public key.
   * @param keyPairCryptoKey The keyPair<CryptoKey> to transform.
   * @return <string> An hexadecimal representation of the **public key**.
   * @see keyPairCryptoKey2KeyPairHexadecimal
   */
  public async keyPairCryptoKey2hexadecimalPublicKey(keyPairCryptoKey: KeyPair<CryptoKey>): Promise<string | null | undefined> {
    return !keyPairCryptoKey ? null : (await this.keyPairCryptoKey2KeyPairHexadecimal(keyPairCryptoKey))?.publicKey;
  }

  /**
   * Transforms a **keyPair<CryptoKey>** into an **hexadecimal** representation of the private key.
   * @param keyPairCryptoKey The keyPair<CryptoKey> to transform.
   * @return <string> An hexadecimal representation of the **private key**.
   * @see keyPairCryptoKey2KeyPairHexadecimal
   */
  public async keyPairCryptoKey2hexadecimalPrivateKey(keyPairCryptoKey: KeyPair<CryptoKey>): Promise<string | null | undefined> {
    return !keyPairCryptoKey ? null : (await this.keyPairCryptoKey2KeyPairHexadecimal(keyPairCryptoKey))?.privateKey;
  }

  /**
   * Transforms a **keyPair<CryptoKey>** into a **keyPair<JsonWebKey>**.
   * @param keyPairCryptoKey The keyPair<CryptoKey> to transform.
   * @param shaVersion The private key's SHA algorithm version (either sha-1 or sha-256).
   * @return The **keyPair<JsonWebKey>** corresponding to the given keyPair<CryptoKey>.
   * @see keyPairCryptoKey2hexadecimalPrivateKey
   * @see hexadecimalPrivateKey2keyPairJsonWebKey
   */
  public async keyPairCryptoKey2keyPairJsonWebKey(keyPairCryptoKey: KeyPair<CryptoKey>, shaVersion: ShaVersion): Promise<KeyPair<JsonWebKey> | null> {
    const hexadecimalPrivateKey: string | null | undefined = await this.keyPairCryptoKey2hexadecimalPrivateKey(keyPairCryptoKey);
    return !hexadecimalPrivateKey ? null : this.hexadecimalPrivateKey2keyPairJsonWebKey(hexadecimalPrivateKey, shaVersion);
  }

  /**
   * Transforms a **keyPair<JsonWebKey>** into a **keyPair<CryptoKey>**.
   * @param keyPairJsonWebKey The keyPair<JsonWebKey> to transform.
   * @param shaVersion The private key's SHA algorithm version (either sha-1 or sha-256).
   * @return The **keyPair<CryptoKey>** corresponding to the given keyPair<JsonWebKey>.
   */
  public async keyPairJsonWebKey2keyPairCryptoKey(keyPairJsonWebKey: KeyPair<JsonWebKey>, shaVersion: ShaVersion): Promise<KeyPair<CryptoKey>> {
    return await this.rsaUtils.importKeyPair('jwk', keyPairJsonWebKey.privateKey, 'jwk', keyPairJsonWebKey.publicKey, shaVersion);
  }

  /**
   * Transforms a **privateKey <JsonWebKey>** into a **keyPair<JsonWebKey>**.
   * @param privateKeyJsonWebKey The private key <JsonWebKey> to transform.
   * @param shaVersion The private key's SHA algorithm version (either sha-1 or sha-256).
   * @return The **keyPair<JsonWebKey>** corresponding to the given private key <JsonWebKey>.
   */
  public privateKeyJsonWebKey2keyPairJsonWebKey(privateKeyJsonWebKey: JsonWebKey, shaVersion: ShaVersion): KeyPair<JsonWebKey> {
    return keyPairFromPrivateKeyJwk(privateKeyJsonWebKey, shaVersion);
  }

  /**
   * Transforms a **privateKey <JsonWebKey>** into a **keyPair<CryptoKey>**.
   * @param privateKeyJsonWebKey The private key <JsonWebKey> to transform.
   * @param shaVersion The private key's SHA algorithm version (either sha-1 or sha-256).
   * @return The **keyPair<CryptoKey>** corresponding to the given private key <JsonWebKey>.
   * @see keyPairJsonWebKey2keyPairCryptoKey
   * @see privateKeyJsonWebKey2keyPairJsonWebKey
   */
  public async privateKeyJsonWebKey2keyPairCryptoKey(privateKeyJsonWebKey: JsonWebKey, shaVersion: ShaVersion): Promise<KeyPair<CryptoKey> | null> {
    return !privateKeyJsonWebKey
      ? null
      : await this.keyPairJsonWebKey2keyPairCryptoKey(this.privateKeyJsonWebKey2keyPairJsonWebKey(privateKeyJsonWebKey, shaVersion), shaVersion);
  }

  /**
   * Transforms a **keyPair<CryptoKey>** into an **hexadecimal** representation of the public key's **fingerprint**.
   * @param keyPairCryptoKey The keyPair<CryptoKey> to transform
   * @return An hexadecimal representation of the **public key's fingerprint**.
   * @see getHexadecimalKeyFingerPrint
   * @see keyPairCryptoKey2hexadecimalPublicKey
   */
  public async keyPairCryptoKey2hexadecimalPublicKeyFingerPrint(keyPairCryptoKey: KeyPair<CryptoKey>): Promise<string | null> {
    return this.getHexadecimalKeyFingerPrint(await this.keyPairCryptoKey2hexadecimalPublicKey(keyPairCryptoKey));
  }

  /**
   * Gets the **fingerprint** of an hexadecimal represented key.
   * @param hexadecimalKey The hexadecimal represented key to get the fingerprint of.
   * @return The **fingerprint** of the given hexadecimal key (last 32 digits).
   */
  public getHexadecimalKeyFingerPrint(hexadecimalKey: string | null | undefined): string {
    return (hexadecimalKey || '').slice(-32);
  }

  /**
   * Gets the public key's **fingerprint** of a DataOwner.
   * @param dataOwner The DataOwner to get public key's fingerprint of.
   * @return The **fingerprint** of the public key (last 32 digits).
   * @see getHexadecimalKeyFingerPrint
   */
  public getPublicKeyFingerPrintByDataOwner(dataOwner: DataOwner): string {
    return this.getHexadecimalKeyFingerPrint(dataOwner?.publicKey);
  }

  /**
   * Gets the public & AES exchange keys' **fingerprint** of a DataOwner.
   * @param dataOwner The DataOwner to get public & AES exchange keys' fingerprint of.
   * @return **Fingerprints** of the public & AES exchange keys (last 32 digits).
   * @note Keys are taken from publicKey, aesExchangeKeys and publicKeysForOaepWithSha256.
   * @see getHexadecimalKeyFingerPrint
   */
  public getPublicAndAesKeysFingerPrintByDataOwner(dataOwner: DataOwner): string[] {
    return [
      ...new Set(
        [dataOwner?.publicKey || '']
          .concat(Object.keys(dataOwner?.aesExchangeKeys || {}))
          .concat(dataOwner?.publicKeysForOaepWithSha256 || []),
      ),
    ]
      .filter(Boolean)
      .map((publicKey: string) => this.getHexadecimalKeyFingerPrint(publicKey));
  }

  /**
   * Gets the private key's **filename** by data owner id.
   * @param dataOwnerId The data owner id to get the private key's filename from.
   * @return The private key's filename.
   */
  public getPrivateKeyFileNameByDataOwnerId(dataOwnerId: string): string {
    return `${dataOwnerId}.2048.key`;
  }

  /**
   * Gets a map <MapStringOf<string>> of keyPair<CryptoKey>'s (diverted publicKey) fingerprint by data owner id.
   * @param keyPairByDataOwnerId The keyPair<CryptoKey>'s to get the publicKey fingerprint of.
   * @return A map <MapStringOf<string>> of keyPair<CryptoKey>'s (diverted publicKey) fingerprint by data owner id.
   * @see keyPairCryptoKey2hexadecimalPublicKeyFingerPrint
   */
  public async getMapOfKeyPairPublicKeysFingerPrintByDataOwnerId(
    keyPairByDataOwnerId: KeyPairByDataOwnerId | null,
  ): Promise<MapStringOf<string>> {
    const keyPairPublicKeyFingerPrintsByDataOwnerId: MapStringOf<string> = {};
    const dataOwnerIds: string[] = Object.keys(keyPairByDataOwnerId || {});

    if (!keyPairByDataOwnerId) return keyPairPublicKeyFingerPrintsByDataOwnerId;

    for (const dataOwnerId of dataOwnerIds)
      keyPairPublicKeyFingerPrintsByDataOwnerId[dataOwnerId] = (await this.keyPairCryptoKey2hexadecimalPublicKeyFingerPrint(keyPairByDataOwnerId[dataOwnerId] as KeyPair<CryptoKey>)) || '';

    return keyPairPublicKeyFingerPrintsByDataOwnerId;
  }
}
