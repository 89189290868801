import { Screen } from './service/FlowManagerService';
import { ScreenName } from './appointment-flow-screens/service/types';
import { AppointmentTypeAndPlace } from '@icure/api';
import { selectAppointmentTypesByPlaceId } from '../services/utils';
import { FlowState } from './AppointmentFlow';

export const APPOINTMENT_FLOW_SCREENS: Screen[] = [
  {
    name: ScreenName.PLACE_CHOICE,
  },
  {
    name: ScreenName.NEW_PATIENT_QUESTION,
    condition: ({ patient, healthcareParty, appointmentTypes }: FlowState) =>
      !patient?.visitedHealthcarePartyIds?.includes(healthcareParty?.id!) &&
      !!appointmentTypes?.some(type => type.acceptsNewPatients === false),
  },
  {
    name: ScreenName.NEW_PATIENT_REJECTION,
    condition: ({ isNewPatient, appointmentTypes, placeId }: FlowState) =>
      !!(
        isNewPatient &&
        selectAppointmentTypesByPlaceId(appointmentTypes || [], placeId!)?.every(
          (type: AppointmentTypeAndPlace) => !type.acceptsNewPatients,
        )
      ),
  },
  {
    name: ScreenName.CALENDAR_ITEM_TYPE_CHOICE,
    condition: ({ appointmentTypes, placeId }: FlowState) =>
      selectAppointmentTypesByPlaceId(appointmentTypes || [], placeId!, true).length > 1,
  },
  {
    name: ScreenName.TIMESLOT_CHOICE,
  },
  {
    name: ScreenName.IDENTIFICATION,
    condition: ({ timeslot }: FlowState) => !!timeslot,
  },
  {
    name: ScreenName.VALIDATION,
  },
  {
    name: ScreenName.SAVE_APPOINTMENT,
  },
];
