import {CryptoActorStubWithType, CryptoStrategies, DataOwner, DataOwnerWithType, KeyPair} from '@icure/api';
import {
  BooleanByKeyPairFingerprint,
  KeyData,
  KeyPairByDataOwnerId,
  KeyPairCkByKeyPairFingerprint,
  MapStringOf,
  RecoveredAndAuthentifiedKeysByDataOwnerId,
} from '../model/rsa.model';
import {hexPublicKeysWithSha1Of, hexPublicKeysWithSha256Of} from '@icure/api/icc-x-api/crypto/utils';
import {RsaHelper} from './rsa.helper';

export class IcureApiCryptoStrategies implements CryptoStrategies {
  private readonly keyPair: KeyPair<CryptoKey> | undefined;
  private readonly verifiedSelfKeys: BooleanByKeyPairFingerprint | undefined;
  public rsaHelper: RsaHelper;

  constructor(
    keyPair: KeyPair<CryptoKey> | undefined,
    verifiedSelfKeys: BooleanByKeyPairFingerprint | undefined,
    rsaHelper: RsaHelper,
  ) {
    this.keyPair = keyPair;
    this.verifiedSelfKeys = verifiedSelfKeys;
    this.rsaHelper = rsaHelper;
  }

  /**
   * Method called during initialisation of the crypto API to validate keys recovered through iCure's recovery methods and/or to allow recovery of
   * missing keys using means external to iCure.
   * On startup the iCure sdk will try to load all keys for the current data owner and its parent hierarchy: if the sdk can't find some of the keys
   * for any of the data owners (according to the public keys for the data owner in the iCure server) and/or the sdk could recover some private keys
   * but can't verify the authenticity of the key pairs this method will be called.
   * The recovered and verified keys will automatically be cached using the current api {@link KeyStorageFacade} and {@link StorageFacade}
   *
   * The input is an array containing an object for each data owner part of the current data owner hierarchy. The objects are ordered from the data
   * for the topmost parent of the current data owner hierarchy (first element) to the data for the current data owner (last element). Each object
   * contains:
   * - dataOwner: the data owner entity that this object refers to
   * - unknownKeys: all public keys (in hex-encoded spki format) of `dataOwner` for which the authenticity status (verified or unverified) is unknown
   *   (no result was cached from a previous api instantiation and the key was not generated on the current device).
   * - unavailableKeys: all public keys (in hex-encoded spki format) of `dataOwner` for which the sdk could not recover a private key. May overlap
   *   (partially or completely) with `unknownKeys`.
   *
   * The returned value must be an object associating to each data owner id an object with:
   * - `recoveredKeys`: all recovered keys (will be automatically considered as verified), by fingerprint.
   * - `keyAuthenticity`: an object associating to each public key fingerprint its authenticity. Note that if any of the keys from `unknownKeys` is
   *   completely missing from this object the key will be considered as unverified in this api instance (same as if associated to false), but this
   *   value won't be cached (will be again part of `unknownKeys` in future instances.
   * @param keysData all information on unknown and unavailable keys for each data owner part of the current data owner hierarchy.
   * @param cryptoPrimitives cryptographic primitives you can use to support the process.
   * @return all recovered keys and key authenticity information, by data owner.
   * @see getDataOwnersWithMissingPrivateKey
   * @see getDataOwnersWithPublicKey
   * @see getDataOwnersWithoutPublicKey
   * @see areEnoughPrivateKeysLoaded
   * @see unavailableKeys2RecoveredAndAuthentifiedKeysByDataOwnerId
   * @see openImportPrivateKeyDialog
   * @see keyPairByDataOwnerId2RecoveredAndAuthentifiedKeysByDataOwnerId
   * @ppublic
   */
  public async recoverAndVerifySelfHierarchyKeys(
    keysData: KeyData[],
  ): Promise<RecoveredAndAuthentifiedKeysByDataOwnerId> {
    /*
    const dataOwnersWithMissingPrivateKey: DataOwner[] = this.getDataOwnersWithMissingPrivateKey(keysData);
    const dataOwnersWithPublicKey: DataOwner[] = this.getDataOwnersWithPublicKey(dataOwnersWithMissingPrivateKey);
    const dataOwnersWithoutPublicKey: DataOwner[] = this.getDataOwnersWithoutPublicKey(dataOwnersWithMissingPrivateKey);

    // If we have enough already loaded private keys (being either SHA-1 or SHA-256), validate initialization
    if (this.areEnoughPrivateKeysLoaded(keysData, dataOwnersWithMissingPrivateKey)) return this.unavailableKeys2RecoveredAndAuthentifiedKeysByDataOwnerId(keysData);
     */

    // Force new keypair generation by returning empty map of known keys
    return this.unavailableKeys2RecoveredAndAuthentifiedKeysByDataOwnerId(keysData);

    /*
    // Do import private key
    const keyPairByDataOwnerId: KeyPairByDataOwnerId | null = await this.openImportPrivateKeyDialog(dataOwnersWithPublicKey);

    // Append (by reference) data owners without keyPair, SDK will then call us back to generate missing keyPairs
    this.appendDataOwnersWithoutKeyPairForPrivateKeysImport(keyPairByDataOwnerId, dataOwnersWithoutPublicKey);

    return await this.keyPairByDataOwnerId2RecoveredAndAuthentifiedKeysByDataOwnerId(keyPairByDataOwnerId);
     */
  }

  /**
   * The correct initialisation of the crypto API requires that at least 1 verified (or device) key pair is available for each data owner part of the
   * current data owner hierarchy. If no verified key is available for any of the data owner parents the api initialisation will automatically fail,
   * however if there is no verified key for the current data owner you can instead create a new crypto key.
   * @param self the current data owner.
   * @param cryptoPrimitives cryptographic primitives you can use to support the process.
   * @return depending on which values you return the api initialisation will proceed differently:
   * - If this method returns true a new key will be automatically generated by the sdk.
   * - If this method returns a key pair the crypto api loads the key pair and considers it as a device key.
   * - If this method returns false the initialisation will fail with a predefined error.
   * - If this method throws an error the initialisation will propagate the error.
   * @see openGenerateNewKeyPairDialog
   * @public
   */
  public async generateNewKeyForDataOwner(self: DataOwnerWithType): Promise<KeyPair<CryptoKey> | boolean> {
    return true;
  }

  /**
   * Verifies if the public keys of a data owner which will be the delegate of a new exchange key do actually belong to the person the data owner
   * represents. This method is not called when the delegate would be the current data owner for the api.
   *
   * The user will have to obtain the verified public keys of the delegate from outside iCure, for example by email with another hcp, by checking the
   * personal website of the other user, or by scanning verification qr codes at the doctor office...
   *
   * As long as one of the public keys is verified the creation of a new exchange key will succeed. If no public key is verified the operation will
   * fail.
   * @param delegate the potential data owner delegate.
   * @param publicKeys public keys requiring verification, in spki hex-encoded format.
   * @param cryptoPrimitives cryptographic primitives you can use to support the process.
   * @return all verified public keys, in spki hex-encoded format.
   */
  public async verifyDelegatePublicKeys(delegate: CryptoActorStubWithType, publicKeys: string[]): Promise<string[]> {
    return Promise.resolve(publicKeys);
  }

  /**
   * Specifies if a data owner requires anonymous delegations, i.e. his id should not appear unencrypted in new secure delegations. This should always
   * be the case for patient data owners.
   * @param dataOwner a data owner.
   * @return true if the delegations for the provided data owner should be anonymous.
   */
  public dataOwnerRequiresAnonymousDelegation(dataOwner: CryptoActorStubWithType): boolean {
    return dataOwner.type !== 'hcp';
  }

  /**
   * Get data owners whose private key is missing {@link KeyStorageFacade}.
   * @param keysData The keys data received from Icure Api.
   * @return The data owners whose private key is missing.
   * @private
   */
  private getDataOwnersWithMissingPrivateKey(keysData: KeyData[]): DataOwner[] {
    return keysData.map((keyData: KeyData) => keyData?.dataOwner?.dataOwner).filter(Boolean);
  }

  /**
   * Filter out data owners without public key.
   * @param dataOwners The data owners to filter.
   * @return The data owners with public key.
   * @private
   */
  private getDataOwnersWithPublicKey(dataOwners: DataOwner[]): DataOwner[] {
    return dataOwners
      .filter((dataOwners: DataOwner) => !!dataOwners.publicKey || dataOwners.publicKeysForOaepWithSha256?.length)
      .filter(Boolean);
  }

  /**
   * Filter out data owners with public key.
   * @param dataOwners The data owners to filter.
   * @return The data owners without public key.
   * @private
   */
  private getDataOwnersWithoutPublicKey(dataOwners: DataOwner[]): DataOwner[] {
    return dataOwners
      .filter((dataOwners: DataOwner) => !dataOwners.publicKey && !dataOwners.publicKeysForOaepWithSha256?.length)
      .filter(Boolean);
  }

  /**
   * Open import private key(s) dialog and handle its closing.
   * @param dataOwners The data owners to import private key(s) for.
   * @return KeyPairByDataOwnerId when private key(s) is / are successfully imported, null otherwise.
   * @see handleDialogClosing
   * @private
   */
  private async openImportPrivateKeyDialog(dataOwners: DataOwner[]): Promise<KeyPairByDataOwnerId | null> {
    console.log({
      who: 'openImportPrivateKeyDialog',
      dataOwners: dataOwners,
    });
    return null;
  }

  /**
   * Transform a **keyPairByDataOwnerId** into a **recoveredAndAuthentifiedKeysByDataOwnerId**.
   * @param keyPairByDataOwnerId The key pair by data owner id map.
   * @return The recovered and authentified keys by data owner id map.
   * @see getMapOfKeyPairPublicKeysFingerPrintByDataOwnerId
   * @private
   */
  private async keyPairByDataOwnerId2RecoveredAndAuthentifiedKeysByDataOwnerId(
    keyPairByDataOwnerId: KeyPairByDataOwnerId | null,
  ): Promise<RecoveredAndAuthentifiedKeysByDataOwnerId> {
    const keyPairPublicKeyFingerPrintsByDataOwnerId: MapStringOf<string> =
      await this.rsaHelper.getMapOfKeyPairPublicKeysFingerPrintByDataOwnerId(keyPairByDataOwnerId);

    return !keyPairByDataOwnerId
      ? null
      : Object.fromEntries(
          Object.keys(keyPairByDataOwnerId || {})
            .map((dataOwnerId: string) => {
              const publicKeyFingerPrint: string = keyPairPublicKeyFingerPrintsByDataOwnerId[dataOwnerId];
              return [
                dataOwnerId,
                {
                  keyAuthenticity: !publicKeyFingerPrint ? {} : ({ [publicKeyFingerPrint]: true } as BooleanByKeyPairFingerprint),
                  recoveredKeys: !publicKeyFingerPrint ? {} : ({ [publicKeyFingerPrint]: keyPairByDataOwnerId[dataOwnerId] } as KeyPairCkByKeyPairFingerprint),
                },
              ];
            })
            .filter(Boolean),
        );
  }

  /**
   * Transform **unavailableKeys** (from Icure `KeyData`) into a **recoveredAndAuthentifiedKeysByDataOwnerId**.
   * @param keysData The keys data received from Icure Api.
   * @return The recovered and authentified keys by data owner id map.
   * @private
   */
  private unavailableKeys2RecoveredAndAuthentifiedKeysByDataOwnerId(
    keysData: KeyData[],
  ): RecoveredAndAuthentifiedKeysByDataOwnerId {
    return keysData
      .filter((it: KeyData) => it.dataOwner?.dataOwner?.id)
      .reduce((acc: RecoveredAndAuthentifiedKeysByDataOwnerId, it: KeyData) => {
        acc[it.dataOwner.dataOwner.id!] = {
          keyAuthenticity: {},
          recoveredKeys: {},
        };
        return acc;
      }, {} as RecoveredAndAuthentifiedKeysByDataOwnerId);
  }

  /**
   * **Checks if (enough) private keys are loaded.**
   *
   * - If the number of missing keys is less than the total number of public keys, then private keys are loaded.
   * - Could be with have SHA-1 (`publicKey`) or SHA-256 (`publicKeysForOaepWithSha256`) keys paris.
   * - All of that being by dataOwners.
   *
   * @param keysData The keys data received from Icure Api.
   * @param dataOwnersWithMissingPrivateKey The data owners with missing private key.
   * @return True if private keys are loaded, false otherwise.
   * @see getTotalPublicKeysByDataOwnersIds
   * @see getNumberOfMissingKeysByDataOwnerIds
   * @private
   */
  private areEnoughPrivateKeysLoaded(keysData: KeyData[], dataOwnersWithMissingPrivateKey: DataOwner[]): boolean {
    const totalPublicKeysByDataOwnersIds: MapStringOf<number> = this.getTotalPublicKeysByDataOwnersIds(
      dataOwnersWithMissingPrivateKey,
    );
    const numberOfMissingKeysByDataOwnerIds: MapStringOf<number> = this.getNumberOfMissingKeysByDataOwnerIds(keysData);

    return Object.keys(numberOfMissingKeysByDataOwnerIds).every((dataOwnerId: string): boolean => (numberOfMissingKeysByDataOwnerIds[dataOwnerId] || 0) < (totalPublicKeysByDataOwnersIds[dataOwnerId] || 0));
  }

  /**
   * **Get the total number of public keys by data owners ids.**
   * @param dataOwners The data owners to get the total number of public keys for.
   * @return The total number of public keys by data owners ids.
   * @see hexPublicKeysWithSha1Of
   * @see hexPublicKeysWithSha256Of
   * @private
   */
  private getTotalPublicKeysByDataOwnersIds(dataOwners: DataOwner[]): MapStringOf<number> {
    return dataOwners.reduce(
      (acc: MapStringOf<number>, dataOwner: DataOwner): MapStringOf<number> => ({
        ...acc,
        [dataOwner.id!]: [...hexPublicKeysWithSha1Of(dataOwner)].concat([...hexPublicKeysWithSha256Of(dataOwner)]).length,
      }),
      {} as MapStringOf<number>,
    );
  }

  /**
   * **Get the number of missing keys by data owners ids.**
   * @param keysData The keys data received from Icure Api.
   * @return The number of missing keys by data owners ids.
   * @private
   */
  private getNumberOfMissingKeysByDataOwnerIds(keysData: KeyData[]): MapStringOf<number> {
    return keysData
      .filter((it: KeyData) => it.dataOwner?.dataOwner?.id)
      .reduce((acc: MapStringOf<number>, it: KeyData) => {
        acc[it.dataOwner.dataOwner.id!] = (it.unavailableKeys ?? []).length;
        return acc;
      }, {} as MapStringOf<number>);
  }

  /**
   * **Append (by reference) data owners without key pair** (to `keyPairByDataOwnerId`).
   * - That being, during import process of missing private keys.
   * - SDK will then call us back to generate missing keyPair for data owners.
   * @param keyPairByDataOwnerId - The key pair by data owner id map.
   * @param dataOwnersWithoutPublicKey - The data owners without public key.
   * @return void (`keyPairByDataOwnerId` is being updated by reference)
   * @private
   */
  private appendDataOwnersWithoutKeyPairForPrivateKeysImport(
    keyPairByDataOwnerId: KeyPairByDataOwnerId | null,
    dataOwnersWithoutPublicKey: DataOwner[],
  ): void {
    if (!keyPairByDataOwnerId) return;

    (dataOwnersWithoutPublicKey || []).forEach((dataOwnerWithoutPublicKey: DataOwner): void => {
      keyPairByDataOwnerId[dataOwnerWithoutPublicKey.id!] = {
        privateKey: undefined,
        publicKey: undefined,
      } as unknown as KeyPair<CryptoKey>;
    });
  }
}
