import { useTranslation } from 'react-i18next';
import { formatHealthcarePartyName, getUniquePlacesFromAppointmentTypesAndHCP } from '../../services/utils';
import { Place } from '@icure/api';
import AddressBlock from '../AddressBlock';
import { FlowState } from '../AppointmentFlow';
import { useEffect, useState } from 'react';

interface Props {
  flowState: FlowState;
  onSubmit: (flowState: FlowState) => void;
}

const PlaceChoice = function ({
  flowState: { appointmentTypes, placeId: initPlaceId, healthcareParty },
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const [placeId, setPlaceId] = useState<string>();
  const [places, setPlaces] = useState<Place[]>();
  useEffect(() => {
    if (!placeId) {
      (async () => {
        const uniquePlacesList = getUniquePlacesFromAppointmentTypesAndHCP(appointmentTypes!, healthcareParty);
        setPlaces(uniquePlacesList);
        setPlaceId(initPlaceId || uniquePlacesList[0].id!);
      })();
    }
  }, [placeId]);

  if (!places || !appointmentTypes || !placeId || !healthcareParty) {
    return <div />;
  }

  return (
    <div>
      <div className="healthcare_party_info">
        <h1 className="main-title">{formatHealthcarePartyName(healthcareParty)}</h1>
        {places?.length > 1 && (
          <>
            <div className="place-picker">
              {places.map((place: Place, index: number) => (
                <div
                  key={index}
                  onClick={() => setPlaceId(place.id!)}
                  className={placeId === place.id ? 'selected' : ''}
                >
                  {place?.name || `${t('UI.PLACE')} ${index + 1}`}
                </div>
              ))}
            </div>
          </>
        )}

        <AddressBlock
          acceptsChange={getUniquePlacesFromAppointmentTypesAndHCP(appointmentTypes!, healthcareParty).length > 1}
          acceptsAppointments={appointmentTypes.some(type => !type.placeId || type.placeId === placeId)}
          isExpanded={true}
          onSelect={() => onSubmit({ placeId })}
          place={places.find((place: Place) => place.id! === placeId)!}
        />
      </div>
    </div>
  );
};
export default PlaceChoice;
