import { JwtAuthenticationProvider, User } from '@icure/api';
import { ICURE_HOST } from './constants';
import { AuthenticationProvider } from '@icure/api/icc-x-api/auth/AuthenticationProvider';
import { IcureApiService } from "./icure-api.service";
import { IcureTokensHelper } from "../helpers/icure-tokens.helper";
import { MapStringOf } from "../model/rsa.model";
import { Credentials, IcureTokens } from "../model/credentials.model";
import { AuthenticationDetails } from "@icure/api/icc-x-api";
import { isIcureApiInitialized } from "../helpers/icure-api.helper";

let icureApiPromise: Promise<IcureApiService> | undefined = undefined;
let lastCredentials: Credentials | undefined = undefined;

export const getApi = async (credentials: Credentials, customHeaders?: MapStringOf<string> | undefined | null): Promise<IcureApiService> => {
  if (icureApiPromise && credentialsAreIdentical(credentials, lastCredentials)) return icureApiPromise;

  // Save for next hit (could vary once groupId is defined)
  lastCredentials = credentials;

  const authenticationOptions: AuthenticationProvider | AuthenticationDetails | undefined = getAuthenticationOptions(credentials);
  if (!authenticationOptions) {
    IcureTokensHelper.deleteAll();
    throw new Error('Invalid authentication options', authenticationOptions);
  }

  const icureApi: IcureApiService = new IcureApiService();
  await icureApi.initialise(ICURE_HOST, getIcureSdkCustomHeaders(customHeaders), authenticationOptions);

  // Not logged in yet
  if (!isIcureApiInitialized(icureApi)) {
    IcureTokensHelper.deleteAll();
    throw new Error('Failer to initialize Icure api');
  }

  // Get current user
  const currentUser: User | undefined | void = await icureApi.userApi?.getCurrentUser().catch(e => console.error('Could not get current user', e));

  // Get Icure tokens
  const iCureTokens: IcureTokens | undefined | void = await icureApi.authApi?.authenticationProvider?.getIcureTokens().catch(e => console.error('Could not get Icure tokens', e));

  // @ts-ignore
  IcureTokensHelper.saveUsername(icureApi.authApi?.authenticationProvider?.['username']);

  // @ts-ignore
  IcureTokensHelper.savePassword(icureApi.authApi?.authenticationProvider?.['password']);

  // Save Icure tokens in session storage
  iCureTokens && IcureTokensHelper.saveTokens(iCureTokens);

  // Save groupId in session storage
  currentUser?.groupId && IcureTokensHelper.saveGroupId(currentUser.groupId);

  // For next calls / singleton
  icureApiPromise = Promise.resolve(icureApi);

  return icureApiPromise;
};

const getIcureSdkCustomHeaders = (customHeaders?: MapStringOf<string> | undefined | null): MapStringOf<string> => {
  return {
    ...(customHeaders || {}),
    'X-Bypass-Session': 'true',
    'force-authentication': 'false',
    'ngsw-bypass': 'true',
  } as MapStringOf<string>;
}

const getAuthenticationOptions = (credentials: Credentials | undefined): AuthenticationDetails | AuthenticationProvider | undefined => {
  const username: string | undefined = credentials?.username;
  const password: string | undefined = credentials?.password;

  // Both username && password are required + username should either be an email or a groupId/usrId
  if (!password || (!(username ?? '').includes('@') && !(username ?? '').includes('/'))) return;

  // Provide credentials as Credentials
  credentials = new Credentials({username, password});

  return (
      getAuthenticationOptionsUsingCredentials(credentials) ||
      getAuthenticationOptionsUsingIcureTokens(credentials)
  );
}

const getAuthenticationOptionsUsingIcureTokens = (credentials: Credentials | undefined = undefined): AuthenticationProvider | undefined => {
  // JWT auth, either from credentials or from session storage
  const iCureTokens: IcureTokens | undefined = getIcureTokensFromCredentialsOrFromSessionStorage(credentials);
  if (!iCureTokens?.token || !iCureTokens?.refreshToken) return;

  // JWT auth via iCureTokens
  return new JwtAuthenticationProvider(
      undefined as any,
      undefined,
      undefined,
      undefined,
      iCureTokens,
  ) as AuthenticationProvider;
}

const getAuthenticationOptionsUsingCredentials = (credentials: Credentials | undefined): AuthenticationDetails | undefined => {
  if (!credentials?.password || (!credentials?.username && !(credentials?.groupId && credentials?.userId))) return;

  return {
    username: credentials.getFullUsername(),
    password: credentials.password,
  } as unknown as AuthenticationDetails;
}

const getIcureTokensFromCredentialsOrFromSessionStorage = (credentials: Credentials | undefined = undefined): IcureTokens | undefined => {
  return getIcureTokensFromCredentials(credentials) || IcureTokensHelper.getTokens();
}

const getIcureTokensFromCredentials = (credentials: Credentials | undefined = undefined): IcureTokens | undefined => {
  return credentials?.icureToken?.token && credentials?.icureToken?.refreshToken ? credentials.icureToken : undefined;
}

const credentialsAreIdentical = (credentials: Credentials | undefined, lastCredentials: Credentials | undefined): boolean => {
  return (
    Boolean(credentials?.username)
    && Boolean(credentials?.password)
    && credentials?.username === lastCredentials?.username
    && credentials?.password === lastCredentials?.password
  );
}