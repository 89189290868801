import { IcureApiService } from "../services/icure-api.service";

/**
 * IcureApi is initialized if we have **at least** one sub-api (initialization would fail otherwise), check for a few ones.
 * @public
 */
export function isIcureApiInitialized(icureApi: IcureApiService): boolean {
  return Boolean(
    icureApi?.accessLogApi &&
      icureApi?.calendarItemApi &&
      icureApi?.contactApi &&
      icureApi?.patientApi &&
      icureApi?.userApi,
  );
}
