import {
  AccessLog,
  CalendarItem,
  Classification,
  Contact,
  Document,
  EncryptedEntity,
  Form,
  HealthElement,
  hex2ua,
  Invoice,
  KeyPair,
  MaintenanceTask,
  Message,
  Patient,
  Receipt,
  retry,
  Service,
} from '@icure/api';
import {IcureApiService} from '../services/icure-api.service';
import {
  EncryptionDecryptionKeypairsForDataOwnerHierarchy,
  KeyPairByDataOwnerId,
  KeyPairsByDataOwnerId,
} from '../model/rsa.model';
import {
  ENCRYPTED_ENTITIES_TYPE_AND_PROPERTY_NAMES,
  ShareMetadataBehaviours,
  ShareMetadataBehavioursByDelegateId,
} from '../model/encrypted-entity.model';

export class CryptoHelper {
  constructor(private api: IcureApiService) {}

  /**
   * **Decrypts the secretIds** (delegations / secretIds) of a given **encryptedEntity**.
   *
   * IE: The cryptographic link **from parent to child**.
   *  - Parents' secretIds (decrypted delegations / secretIds) correspond to children's secretForeignKeys.
   *  - Children's cryptedForeignKeys / owningEntityIds (once decrypted) correspond to parents' uuid.
   *
   * **EncryptedEntity could be any of:**
   * - Message
   * - Patient
   *
   * @param encryptedEntity The encryptedEntity to decrypt the secretIds of.
   * @returns The decrypted secretIds (string[]).
   * @see enforceInstanceOfEncryptedEntity
   */
  public async decryptSecretIdsOf(encryptedEntity: EncryptedEntity): Promise<string[]> {
    if (!encryptedEntity) return [];

    encryptedEntity = this.enforceInstanceOfEncryptedEntity(encryptedEntity);

    // Shall one of those be missing (respectfully < Icure SDK v8.0.0 ; >= Icure SDK v8.0.0), would decryption fail.
    return !Object.keys(encryptedEntity?.delegations || {})?.length && !Object.keys(encryptedEntity?.securityMetadata?.secureDelegations || {})?.length
      ? []
      : encryptedEntity instanceof Message
        ? await retry<string[]>(() => this.api?.messageXApi?.decryptSecretIdsOf(encryptedEntity as Message) ?? Promise.resolve([])).catch(e => [])
        : encryptedEntity instanceof Patient
          ? await retry<string[]>(() => this.api?.patientApi?.decryptSecretIdsOf(encryptedEntity as Patient) ?? Promise.resolve([])).catch(e => [])
          : [];
  }

  /**
   * **Decrypts the parentIds** (cryptedForeignKeys / owningEntityIds) of a given **encryptedEntity**.
   *
   * IE: The cryptographic link **from child to parent**.
   *  - Parents' secretIds (decrypted delegations / secretIds) correspond to children's secretForeignKeys / owningEntityIds.
   *  - Children's cryptedForeignKeys / owningEntityIds (once decrypted) correspond to parents' uuid.
   *
   * **EncryptedEntity could be any of:**
   * - AccessLog (parentIds are patientIds)
   * - CalendarItem (parentIds are patientIds)
   * - Classification (parentIds are patientIds)
   * - Contact (parentIds are patientIds)
   * - Document (parentIds are messageIds)
   * - Form (parentIds are patientIds)
   * - HealthElement (parentIds are patientIds)
   * - Invoice (parentIds are patientIds)
   * - Message (parentIds are patientIds)
   * - Service (parentIds are patientIds)
   *
   * @param encryptedEntity The encryptedEntity to decrypt the parentIds of.
   * @returns The decrypted parentIds (string[]).
   * @see enforceInstanceOfEncryptedEntity
   */
  public async decryptParentIdsOf(encryptedEntity: EncryptedEntity): Promise<string[]> {
    encryptedEntity = this.enforceInstanceOfEncryptedEntity(encryptedEntity);

    // Shall one of those be missing (respectfully < Icure SDK v8.0.0 ; >= Icure SDK v8.0.0), would decryption fail.
    return !Object.keys(encryptedEntity?.cryptedForeignKeys || {})?.length && !Object.keys(encryptedEntity?.securityMetadata?.secureDelegations || {})?.length
      ? []
      : encryptedEntity instanceof AccessLog
      ? await retry<string[]>(() => (this.api.accessLogApi?.decryptPatientIdOf(encryptedEntity as AccessLog) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof CalendarItem
      ? await retry<string[]>(() => (this.api.calendarItemApi?.decryptPatientIdOf(encryptedEntity as CalendarItem) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Classification
      ? await retry<string[]>(() => (this.api.classificationApi?.decryptPatientIdOf(encryptedEntity as Classification) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Contact
      ? await retry<string[]>(() => (this.api.contactApi?.decryptPatientIdOf(encryptedEntity as Contact) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Document
      ? await retry<string[]>(() => (this.api.documentApi?.decryptMessageIdOf(encryptedEntity as Document) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Form
      ? await retry<string[]>(() => (this.api.formApi?.decryptPatientIdOf(encryptedEntity as Form) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof HealthElement
      ? await retry<string[]>(() => (this.api.helementApi?.decryptPatientIdOf(encryptedEntity as HealthElement) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Invoice
      ? await retry<string[]>(() => (this.api.invoiceApi?.decryptPatientIdOf(encryptedEntity as Invoice) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Message
      ? await retry<string[]>(() => (this.api.messageXApi?.decryptPatientIdOf(encryptedEntity as Message) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Service
      ? await retry<string[]>(() => (this.api.contactApi?.decryptPatientIdOf(encryptedEntity as any) ?? Promise.resolve([]))).catch(e => ([]))
      : [];
  }

  /**
   * **Decrypts the AES encryption / decryption keys** of a given **encryptedEntity**.
   * - Includes backward compatibility: use delegations as encryptionKeys when the latter are missing.
   * - Original encryptedEntity is **not mutated**.
   *
   * **EncryptedEntity could be any of:**
   * - AccessLog
   * - CalendarItem
   * - Classification
   * - Contact
   * - Document
   * - Form
   * - HealthElement
   * - Invoice
   * - MaintenanceTask
   * - Message
   * - Patient
   * - Receipt
   * - Service
   *
   * @param encryptedEntity The encryptedEntity to decrypt encryptionKeys of.
   * @returns The AES decrypted encryption / decryption keys (string[]).
   * @see enforceInstanceOfEncryptedEntity
   */
  public async getEncryptionDecryptionKeysOf(encryptedEntity: EncryptedEntity | undefined): Promise<string[]> {
    if (!encryptedEntity?.id) return [];

    encryptedEntity = this.enforceInstanceOfEncryptedEntity(encryptedEntity);

    // Backward compatibility: use delegations as encryptionKeys when the latter are missing.
    if (!Object.keys(encryptedEntity?.encryptionKeys || {})?.length) encryptedEntity!.encryptionKeys = encryptedEntity?.delegations || {};

    // Shall one of those be missing (respectfully < Icure SDK v8.0.0 ; >= Icure SDK v8.0.0), would decryption fail.
    return !Object.keys(encryptedEntity?.encryptionKeys || {})?.length && !Object.keys(encryptedEntity?.securityMetadata?.secureDelegations || {})?.length
      ? []
      : encryptedEntity instanceof AccessLog
      ? await retry<string[]>(() => (this.api.accessLogApi?.getEncryptionKeysOf(encryptedEntity as AccessLog) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof CalendarItem
      ? await retry<string[]>(() => (this.api.calendarItemApi?.getEncryptionKeysOf(encryptedEntity as CalendarItem) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Classification
      ? await retry<string[]>(() => (this.api.classificationApi?.getEncryptionKeysOf(encryptedEntity as Classification) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Contact
      ? await retry<string[]>(() => (this.api.contactApi?.getEncryptionKeysOf(encryptedEntity as Contact) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Document
      ? await retry<string[]>(() => (this.api.documentApi?.getEncryptionKeysOf(encryptedEntity as Document) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Form
      ? await retry<string[]>(() => (this.api.formApi?.getEncryptionKeysOf(encryptedEntity as Form) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof HealthElement
      ? await retry<string[]>(() => (this.api.helementApi?.getEncryptionKeysOf(encryptedEntity as HealthElement) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Invoice
      ? await retry<string[]>(() => (this.api.invoiceApi?.getEncryptionKeysOf(encryptedEntity as Invoice) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof MaintenanceTask
      ? await retry<string[]>(() => (this.api.maintenanceTaskApi?.getEncryptionKeysOf(encryptedEntity as MaintenanceTask) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Message
      ? await retry<string[]>(() => (this.api.messageXApi?.getEncryptionKeysOf(encryptedEntity as Message) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Patient
      ? await retry<string[]>(() => (this.api.patientApi?.getEncryptionKeysOf(encryptedEntity as Patient) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Receipt
      ? await retry<string[]>(() => (this.api.receiptApi?.getEncryptionKeysOf(encryptedEntity as Receipt) ?? Promise.resolve([]))).catch(e => ([]))
      : encryptedEntity instanceof Service
      ? await retry<string[]>(() => (this.api.contactApi?.getEncryptionKeysOf(encryptedEntity as any) ?? Promise.resolve([]))).catch(e => ([]))
      : [];
  }

  /**
   * **Decrypts the AES encryption / decryption keys** of a given **encryptedEntity** and return them **as a CSV**.
   * - Shall there be an issue with encryption / decryption keys decryption, method would return null.
   *
   * @see getEncryptionDecryptionKeysOf
   * @param encryptedEntity The encryptedEntity to decrypt encryptionKeys of.
   * @returns The AES decrypted encryption / decryption keys as CSV string.
   */
  public async getEncryptionDecryptionKeysOfAsCsv(encryptedEntity: EncryptedEntity): Promise<string> {
    const encryptionDecryptionKeys: string[] = await this.getEncryptionDecryptionKeysOf(encryptedEntity);
    return !encryptionDecryptionKeys?.length ? '' : encryptionDecryptionKeys.join(',');
  }

  /**
   * **Shares given encryptedEntity with another data owner**.
   *
   * @param delegateId id of data owner to share encryptedEntity with.
   * @param encryptedEntity the encryptedEntity to share.
   * @param options optional parameters to customize sharing behavior.
   * @param additionalSecretIdsToShare additional secret ids to share (applies to Message | Patient).
   * @returns The updated encryptedEntity (gets mutated).
   * @note When passing additional secret ids to share, already existing secretIds will be kept (concatenated).
   * @see enforceInstanceOfEncryptedEntity
   * @see decryptSecretIdsOf
   */
  public async shareWith(
    delegateId: string,
    encryptedEntity: EncryptedEntity,
    options?: ShareMetadataBehaviours,
    additionalSecretIdsToShare?: string[],
  ): Promise<EncryptedEntity> {
    encryptedEntity = this.enforceInstanceOfEncryptedEntity(encryptedEntity);

    // EncryptedEntity's existing secretIds
    const encryptedEntitySecretIds: string[] = encryptedEntity instanceof Message || encryptedEntity instanceof Patient ? await this.decryptSecretIdsOf(encryptedEntity) : [];

    // Add additional secret ids to share to existing ones
    const sharedSecretIds: string[] = [...new Set((encryptedEntitySecretIds || []).concat(additionalSecretIdsToShare || []))].filter(Boolean);

    return !encryptedEntity?.id
      ? encryptedEntity
      : encryptedEntity instanceof AccessLog
      ? await retry<AccessLog>(() => (this.api.accessLogApi?.shareWith(delegateId, encryptedEntity as AccessLog, options) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof CalendarItem
      ? await retry<CalendarItem>(() => (this.api.calendarItemApi?.shareWith(delegateId, encryptedEntity as CalendarItem, options) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Classification
      ? await retry<Classification>(() => (this.api.classificationApi?.shareWith(delegateId, encryptedEntity as Classification, options) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Contact
      ? await retry<Contact>(() => (this.api.contactApi?.shareWith(delegateId, encryptedEntity as Contact, options) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Document
      ? await retry<Document>(() => (this.api.documentApi?.shareWith(delegateId, encryptedEntity as Document, options) ?? Promise.resolve(encryptedEntity as Document))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Form
      ? await retry<Form>(() => (this.api.formApi?.shareWith(delegateId, encryptedEntity as Form, options) ?? Promise.resolve(encryptedEntity as Form))).catch(e => encryptedEntity)
      : encryptedEntity instanceof HealthElement
      ? await retry<HealthElement>(() => (this.api.helementApi?.shareWith(delegateId, encryptedEntity as HealthElement, options) ?? Promise.resolve(encryptedEntity as HealthElement))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Invoice
      ? await retry<Invoice>(() => (this.api.invoiceApi?.shareWith(delegateId, encryptedEntity as Invoice, options) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof MaintenanceTask
      ? await retry<MaintenanceTask>(() => (this.api.maintenanceTaskApi?.shareWith(delegateId, encryptedEntity as MaintenanceTask, options) ?? Promise.resolve(encryptedEntity as MaintenanceTask))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Message
      ? await retry<Message>(() => (this.api.messageXApi?.shareWith(delegateId, encryptedEntity as Message, sharedSecretIds, options) ?? Promise.resolve(encryptedEntity as Message))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Patient
      ? await retry<Patient>(() => (this.api.patientApi?.shareWith(delegateId, encryptedEntity as Patient, sharedSecretIds, options) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Receipt
      ? await retry<Receipt>(() => (this.api.receiptApi?.shareWith(delegateId, encryptedEntity as Receipt, options) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Service
      ? await retry<Service>(() => (this.api.contactApi?.shareWith(delegateId, encryptedEntity as any, options) ?? Promise.resolve(encryptedEntity as any))).catch(e => encryptedEntity)
      : encryptedEntity;
  }

  /**
   * **Shares given encryptedEntity with other data owners**.
   *
   * @param encryptedEntity the encryptedEntity to share.
   * @param delegates map of "ShareMetadataBehaviours" by delegate id, to share encryptedEntity with.
   * @returns The updated encryptedEntity (gets mutated).
   * @warning It is up to the caller to ensure **secret ids concatenation**, when building delegates map (shareWith).
   * @see shareWith
   * @see enforceInstanceOfEncryptedEntity
   */
  public async shareWithMany(
      encryptedEntity: EncryptedEntity,
      delegates: ShareMetadataBehavioursByDelegateId,
  ): Promise<EncryptedEntity> {
    encryptedEntity = this.enforceInstanceOfEncryptedEntity(encryptedEntity);

    return !encryptedEntity?.id
      ? encryptedEntity
      : encryptedEntity instanceof AccessLog
      ? await retry<AccessLog>(() => (this.api.accessLogApi?.shareWithMany(encryptedEntity as AccessLog, delegates) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof CalendarItem
      ? await retry<CalendarItem>(() => (this.api.calendarItemApi?.shareWithMany(encryptedEntity as CalendarItem, delegates) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Classification
      ? await retry<Classification>(() => (this.api.classificationApi?.shareWithMany(encryptedEntity as Classification, delegates) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Contact
      ? await retry<Contact>(() => (this.api.contactApi?.shareWithMany(encryptedEntity as Contact, delegates) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Document
      ? await retry<Document>(() => (this.api.documentApi?.shareWithMany(encryptedEntity as Document, delegates) ?? Promise.resolve(encryptedEntity as Document))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Form
      ? await retry<Form>(() => (this.api.formApi?.shareWithMany(encryptedEntity as Form, delegates) ?? Promise.resolve(encryptedEntity as Form))).catch(e => encryptedEntity)
      : encryptedEntity instanceof HealthElement
      ? await retry<HealthElement>(() => (this.api.helementApi?.shareWithMany(encryptedEntity as HealthElement, delegates) ?? Promise.resolve(encryptedEntity as HealthElement))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Invoice
      ? await retry<Invoice>(() => (this.api.invoiceApi?.shareWithMany(encryptedEntity as Invoice, delegates) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof MaintenanceTask
      ? await retry<MaintenanceTask>(() => (this.api.maintenanceTaskApi?.shareWithMany(encryptedEntity as MaintenanceTask, delegates) ?? Promise.resolve(encryptedEntity as MaintenanceTask))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Message
      ? await retry<Message>(() => (this.api.messageXApi?.shareWithMany(encryptedEntity as Message, delegates as any) ?? Promise.resolve(encryptedEntity as Message))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Patient
      ? await retry<Patient>(() => (this.api.patientApi?.shareWithMany(encryptedEntity as Patient, delegates as any) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Receipt
      ? await retry<Receipt>(() => (this.api.receiptApi?.shareWithMany(encryptedEntity as Receipt, delegates) ?? Promise.resolve(encryptedEntity))).catch(e => encryptedEntity)
      : encryptedEntity instanceof Service
      ? await retry<Service>(() => (this.api.contactApi?.shareWithMany(encryptedEntity as any, delegates) ?? Promise.resolve(encryptedEntity as any))).catch(e => encryptedEntity)
      : encryptedEntity;
  }

  /**
   * **Enforces** given encryptedEntity to be an instance of a **known EncryptedEntity** (when possible).
   *
   * - Could be we're given a non-strongly typed entity.
   *
   * - Eg: from JSON response / embryo / form / etc... ; which we'll have to deal with.
   *
   * **These methods need to know the type of the encryptedEntity to work properly:**
   *
   * - decryptSecretIdsOf
   * - decryptParentIdsOf
   * - getEncryptionDecryptionKeysOf
   *
   * **EncryptedEntity could be any of:**
   * - AccessLog
   * - CalendarItem
   * - Classification
   * - Contact
   * - Document
   * - Form
   * - HealthElement
   * - Invoice
   * - MaintenanceTask
   * - Message
   * - Patient
   * - Receipt
   * - Service
   * - Any
   *
   * @param encryptedEntity The encryptedEntity to enforce the instance of.
   * @returns The enforced instance of encryptedEntity.
   * @private
   */
  private enforceInstanceOfEncryptedEntity(encryptedEntity: any) {
    for (const typeAndPropertyNames of ENCRYPTED_ENTITIES_TYPE_AND_PROPERTY_NAMES) {
      if (!(typeAndPropertyNames?.propertyNames || []).some(propertyName => propertyName in (encryptedEntity || {}))) continue;
      const encryptedEntityType: string = typeAndPropertyNames?.type;

      return encryptedEntityType === 'AccessLog'
        ? new AccessLog(encryptedEntity)
        : encryptedEntityType === 'CalendarItem'
        ? new CalendarItem(encryptedEntity)
        : encryptedEntityType === 'Classification'
        ? new Classification(encryptedEntity)
        : encryptedEntityType === 'Contact'
        ? new Contact(encryptedEntity)
        : encryptedEntityType === 'Document'
        ? new Document(encryptedEntity)
        : encryptedEntityType === 'Form'
        ? new Form(encryptedEntity)
        : encryptedEntityType === 'HealthElement'
        ? new HealthElement(encryptedEntity)
        : encryptedEntityType === 'Invoice'
        ? new Invoice(encryptedEntity)
        : encryptedEntityType === 'MaintenanceTask'
        ? new MaintenanceTask(encryptedEntity)
        : encryptedEntityType === 'Message'
        ? new Message(encryptedEntity)
        : encryptedEntityType === 'Patient'
        ? new Patient(encryptedEntity)
        : encryptedEntityType === 'Receipt'
        ? new Receipt(encryptedEntity)
        : encryptedEntityType === 'Service'
        ? new Service(encryptedEntity)
        : encryptedEntity;
    }
    return encryptedEntity;
  }

  /**
   * Gets the (RSA) **encryption / decryption keypairs** for **data owner (self) and its hierarchy (parents)**.
   *
   * Both self && parents' keypairs are returned (should there be any parent).
   *
   * @returns The encryption / decryption keypairs for the data owner (self) and its hierarchy (parents).
   * @see getEncryptionDecryptionKeypairsForDataOwnerHierarchy
   */
  public async getEncryptionDecryptionKeypairsForDataOwnerHierarchy(): Promise<EncryptionDecryptionKeypairsForDataOwnerHierarchy | null> {
    return await retry<EncryptionDecryptionKeypairsForDataOwnerHierarchy>(() => this.api.cryptoApi?.getEncryptionDecryptionKeypairsForDataOwnerHierarchy() ?? Promise.reject()).catch(e => null);
  }

  /**
   * Gets map of (RSA) **encryption / decryption keypairs** for **data owner (self) and its hierarchy (parents)**.
   * @returns The map of encryption / decryption keypairs **<KeyPairsByDataOwnerId>**.
   * @see getEncryptionDecryptionKeypairsForDataOwnerHierarchy
   */
  public async getMapOfEncryptionDecryptionKeypairsByDataOwnerId(): Promise<KeyPairsByDataOwnerId> {
    const encryptionDecryptionKeypairsForDataOwnerHierarchy: EncryptionDecryptionKeypairsForDataOwnerHierarchy | null = await this.getEncryptionDecryptionKeypairsForDataOwnerHierarchy();

    return [
      ...[encryptionDecryptionKeypairsForDataOwnerHierarchy?.self || null],
      ...(encryptionDecryptionKeypairsForDataOwnerHierarchy?.parents || []),
    ].reduce<KeyPairsByDataOwnerId>((accumulator, dataOwnerIdAndKeys) => {
      const dataOwnerId: string = dataOwnerIdAndKeys?.dataOwnerId ?? '';
      const keyPairs: KeyPair<CryptoKey>[] = (dataOwnerIdAndKeys?.keys || []).map(key => key.pair);
      return !dataOwnerId || !keyPairs ? accumulator : (accumulator[dataOwnerId] = keyPairs) && accumulator;
    }, {} as KeyPairsByDataOwnerId);
  }

  /**
   * Gets map of **first** (RSA) **encryption / decryption keypair** for **data owner (self) and its hierarchy (parents)**.
   * @returns The map of **first** encryption / decryption keypair **<KeyPairByDataOwnerId>**.
   * @see getMapOfEncryptionDecryptionKeypairsByDataOwnerId
   */
  public async getMapOfEncryptionDecryptionKeypairByDataOwnerId(): Promise<KeyPairByDataOwnerId> {
    const mapOfEncryptionDecryptionKeypairsByDataOwnerId: KeyPairsByDataOwnerId =
      await this.getMapOfEncryptionDecryptionKeypairsByDataOwnerId();

    return Object.entries(mapOfEncryptionDecryptionKeypairsByDataOwnerId).reduce(
      (accumulator, dataOwnerIdAndkeyPairsCryptoKey) => {
        const dataOwnerId: string = dataOwnerIdAndkeyPairsCryptoKey?.[0];
        const keyPair: KeyPair<CryptoKey> = dataOwnerIdAndkeyPairsCryptoKey?.[1]?.[0] as KeyPair<CryptoKey>;
        // @ts-ignore
        return !dataOwnerId || !keyPair ? accumulator : (accumulator[dataOwnerId] = keyPair) && accumulator;
      },
      {},
    );
  }

  /**
   * Gets the (RSA) **encryption / decryption keypairs** of given data owner id.
   * @param dataOwnerId The data owner id to get the encryption / decryption keypairs of.
   * @returns The data owner's encryption / decryption keypairs **<KeyPair<CryptoKey>[]>**.
   * @see getMapOfEncryptionDecryptionKeypairsByDataOwnerId
   */
  public async getEncryptionDecryptionKeypairsByDataOwnerId(dataOwnerId: string): Promise<KeyPair<CryptoKey>[] | null> {
    const mapOfEncryptionDecryptionKeypairsByDataOwnerId: KeyPairsByDataOwnerId = await this.getMapOfEncryptionDecryptionKeypairsByDataOwnerId();
    return !dataOwnerId ? null : (mapOfEncryptionDecryptionKeypairsByDataOwnerId[dataOwnerId] as KeyPair<CryptoKey>[]);
  }

  /**
   * Gets the **first** (RSA) **encryption / decryption keypair** of given data owner id.
   * @param dataOwnerId The data owner id to get the encryption / decryption keypair of.
   * @returns The data owner's **first** encryption / decryption keypair **<KeyPair<CryptoKey>>**.
   * @see getMapOfEncryptionDecryptionKeypairByDataOwnerId
   */
  public async getEncryptionDecryptionKeypairByDataOwnerId(dataOwnerId: string): Promise<KeyPair<CryptoKey> | null> {
    const mapOfEncryptionDecryptionKeypairByDataOwnerId: KeyPairByDataOwnerId = await this.getMapOfEncryptionDecryptionKeypairByDataOwnerId();
    return !dataOwnerId ? null : (mapOfEncryptionDecryptionKeypairByDataOwnerId[dataOwnerId] as KeyPair<CryptoKey>);
  }

  /**
   * **Encrypts or decrypts** given content using:
   * - Either given AES encryption / decryption key.
   * - Or given encryptedEntity's decrypted AES encryption / decryption (first) key.
   * @param method Whether to encrypt or decrypt given content.
   * @param content The content to encrypt or decrypt.
   * @param edKey The AES encryption / decryption key to import.
   * @see importAesKeyAndEncryptOrDecrypt
   * @param encryptedEntity
   */
  public async encryptDecrypt(
    method: 'encrypt' | 'decrypt',
    content: Uint8Array | ArrayBuffer,
    edKey?: string,
    encryptedEntity?: EncryptedEntity,
  ): Promise<Uint8Array | any> {
    if (!content || !(edKey || encryptedEntity)) return content;

    // Encryption / decryption key is either given or fetched from encryptedEntity
    edKey = edKey || (await this.getEncryptionDecryptionKeysOf(encryptedEntity))?.[0];

    try {
      return !edKey ? content : await this.importAesKeyAndEncryptOrDecrypt(method, content, edKey);
    } catch (e) {
      console.error(`Failed to ${method} content with edKey: ${edKey}`, e);
      return content;
    }
  }

  /**
   * **Imports given AES encryption / decryption key and encrypts or decrypts given content.**
   * @param method Whether to encrypt or decrypt given content.
   * @param content The content to encrypt or decrypt.
   * @param edKey The AES encryption / decryption key to import.
   * @returns The encrypted or decrypted content.
   * @private
   */
  private async importAesKeyAndEncryptOrDecrypt(
    method: 'encrypt' | 'decrypt',
    content: Uint8Array | ArrayBuffer,
    edKey: string,
  ): Promise<Uint8Array | ArrayBuffer> {
    const AESUtils = this.api.cryptoApi?.primitives?.AES;
    if (!AESUtils) return content;

    const importedEdKey: CryptoKey = await AESUtils.importKey('raw', hex2ua(edKey.replace(/-/g, '')));
    return await AESUtils[method].bind(AESUtils)(importedEdKey, content);
  }
}
